/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import Home from "layouts/Home/Home";

import '../src/assets/css/global.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { TemplateContextProvider } from "contexts/TemplateSettingsContext";
import { DataContextProvider } from "contexts/DataContext";
import { AuthProvider } from "contexts/AuthContext"
import { AnalyticsProvider } from "contexts/AnalyticsContext";
import SignIn from "layouts/SignIn/SignIn";
import About from "layouts/About/About";
import Services from "layouts/Services/Services";
import Reviews from "layouts/Reviews/Reviews";
import Contact from "layouts/Contact/Contact";
import Properties from "layouts/Properties/Properties";
import Property from "layouts/Property/Property";
import ReviewRequest from "layouts/ReviewRequest/ReviewRequest";
import PrivacyPolicy from "layouts/Policies/PrivacyPolicy";
import Resources from "layouts/Resources/Resources";
import ResourcesLenders from "layouts/Resources/ResourcesLenders";
import ResourcesDownloadables from "layouts/Resources/ResourcesDownloadables";
import ResourcesOtherServices from "layouts/Resources/ResourcesOtherServices";
import Referrals from "layouts/Referrals/Referrals";
import Rentals from "layouts/Rentals/Rentals";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <TemplateContextProvider>
    <AuthProvider>
      <DataContextProvider>
        <ThemeContextWrapper>
          <BackgroundColorWrapper>
          <AnalyticsProvider>
            <BrowserRouter>
              <Routes>
                {/* "Front End" paths */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/reviews" element={<Reviews />} />
                <Route path="/properties" element={<Properties />} />
                <Route path="/properties/:address" element={<Property />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/resources/preferred-lenders" element={<ResourcesLenders />} />
                <Route path="/resources/guides" element={<ResourcesDownloadables />} />
                <Route path="/resources/other-services" element={<ResourcesOtherServices />} />
                <Route path="/resources/rentals" element={<Rentals />} />
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/review-request" element={<ReviewRequest />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* "Admin" paths */}
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route
                  path="*"
                  element={<Navigate to="/" replace />}
                />
              </Routes>
            </BrowserRouter>
            </AnalyticsProvider>
          </BackgroundColorWrapper>
        </ThemeContextWrapper>
      </DataContextProvider>
    </AuthProvider>
  </TemplateContextProvider>
);
