import React from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import './index.css'

export default function About() {
  return (
    <PageLayout 
        heading='About Us' 
        background={'https://images.unsplash.com/photo-1598228723793-52759bba239c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
        content={
          <>
          <p><span className='text-black'>Welcome to our real estate community, where your dreams take center stage. Our dedication to helping individuals and families find their perfect home is at the heart of everything we do. With a wealth of experience in the industry, we understand that real estate transactions are about more than just buying or selling property—they're about guiding our clients toward a brighter future.</span></p>
        
          <p><span className='text-black'>At our team, we pride ourselves on our unwavering dedication to our clients' needs. Whether you're a first-time buyer, seasoned investor, or looking to sell your property, Christol Britton and her team of experts are here to walk alongside you every step of the way. From the initial consultation to the final closing, we're committed to providing personalized solutions that prioritize your goals and aspirations.</span></p>
        
          <p><span className='text-black'>As a locally operated team, we have deep roots in the community and a profound understanding of the local market. Our extensive network and innovative technology allow us to stay ahead of industry trends, providing you with the latest information and insights to make informed decisions.</span></p>
        
          <p><span className='text-black'>We believe in transparency, integrity, and professionalism in all our interactions. Your trust is our most valuable asset, and we go above and beyond to exceed your expectations with our relentless commitment to excellence.</span></p>
        
          <p><span className='text-black'>Discover the difference with our team. Contact us today and let us help you turn your real estate dreams into reality.</span></p>
        </>
        
        } 
    />
  )
}
