import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Form, FormGroup, Input } from 'reactstrap';

const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  const calculateMonthlyPayment = () => {
    const principal = parseFloat(loanAmount);
    const annualInterestRate = parseFloat(interestRate) / 100;
    const monthlyInterestRate = annualInterestRate / 12;
    const loanTermMonths = parseFloat(loanTerm) * 12;

    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  const handleCalculate = (e) => {
    e.preventDefault();
    calculateMonthlyPayment();
  };

  const reset = (e) => {
    e.preventDefault()
    setLoanAmount("")
    setInterestRate("")
    setLoanTerm("")
    setMonthlyPayment(null)
  }

  return (
    <Card>
        <CardHeader>
            <CardTitle tag='h6' style={{fontWeight: 'bold'}}>Mortgage Calculator</CardTitle>
        </CardHeader>
            <CardBody>
            <Form onSubmit={handleCalculate}>
                <FormGroup>
                <label>
                Loan Amount ($):
                </label>
                <Input
                    type="number"
                    value={loanAmount}
                    onChange={(e) => setLoanAmount(e.target.value)}
                    required
                />
                </FormGroup>
                <FormGroup>
                <label>
                Annual Interest Rate (%):
                </label>
                <Input
                    type="number"
                    step="0.01"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                    required
                />
                </FormGroup>
                <FormGroup>
                <label>
                Loan Term (years):
                </label>
                <Input
                    type="number"
                    value={loanTerm}
                    onChange={(e) => setLoanTerm(e.target.value)}
                    required
                />
                </FormGroup>
                <Button type="submit">Calculate Mortgage</Button>
            </Form>
            {monthlyPayment && (
                <div className="mt-2">
                    <h5>Monthly Payment: ${monthlyPayment}</h5>
                    <Button onClick={reset}>Reset</Button>
                </div>
            )}
      </CardBody>
    </Card>
  );
};

export default MortgageCalculator;
