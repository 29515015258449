import React, {useRef, useState} from "react";
import {
  Container,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Col,
  Row,
} from "reactstrap";
import "./contact.css";

export default function Contact() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const form = useRef();

  const handleSubmit = async (e) => {
      e.preventDefault();

      setLoading(true)

      // name, subject, email and message check
      if(!email || !message || !lastName || !firstName || !phoneNumber){
          alert('Please add missing fields.')
          return
      }

      alert('Demo functionality reached!')
      //emailjs functionality
    {/*}
      await emailjs.sendForm('', '', form.current, '')
      .then((result) => {
        alert("Message sent successfully!")
        console.log(result)
      }, (error) => {
        alert("Sorry there seems to be an error...please try again")
        console.log(error)
      });
    {*/}

      handleReset();
  } 

  const handleReset = () => {
      setFirstName('')
      setLastName('')
      setPhoneNumber()
      setEmail('')
      setMessage('')
      setLoading(false)
  }

  return (
    <Container fluid id="contact">
      <div id="contact-card">
        <CardBody>
          <form className="p-3" onSubmit={handleSubmit} ref={form}>
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label style={{ color: "black" }} for="firstNameInput">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={firstName}
                    id="firstNameInput"
                    placeholder="Enter your first name"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label style={{ color: "black" }} for="lastNameInput">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={lastName}
                    id="lastNameInput"
                    placeholder="Enter your last name"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label style={{ color: "black" }} for="phoneNumberInput">
                Phone number
              </Label>
              <Input
                type="number"
                name="number"
                value={phoneNumber}
                id="phoneNumberInput"
                placeholder="Enter your phone number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label style={{ color: "black" }} for="emailInput">
                Email address
              </Label>
              <Input
                type="email"
                name="email"
                value={email}
                id="emailInput"
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <FormText color="muted">
                We'll never share your email with anyone else.
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label for="messageInput">Message</Label>
              <Input
                id="messageInput"
                name="message"
                type="textarea"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                required
              />
            </FormGroup>

            <Button type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </form>
        </CardBody>
      </div>
    </Container>
  );
}
