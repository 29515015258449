import React from 'react'
import ContactForm from 'components/ContactForm/ContactForm'
import PageLayout from 'components/PageLayout/PageLayout'

export default function Contact() {

    return (
        <PageLayout 
            heading='Contact Us Today' 
            background={'https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
            content={
                    <>
                    <p align='center'>
                        <span className='text-black'>Contact us today to find your dream home.</span>
                    </p>
                    <ContactForm />
                    </>
            } 
        />
      )
}
