import React, { useState, useEffect } from 'react'
import { CardBody, CardFooter, CardImg } from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.js";
import { Row, Col } from "reactstrap";
import './index.css'

export default function LendersList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const lendersCollectionRef = collection(db, "lenders");
      const querySnapshot = await getDocs(lendersCollectionRef);

      const lendersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(lendersData);
      setData(lendersData);
    } catch (error) {
      console.error("Error fetching lenders list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
        {loading && !data ? (
            <>
            <p className="text-black">Loading...</p>
            </>
        ) : null}
        {!loading && !data ? <></> : null}
        {!loading && data.length > 0 ? 
            <>
            <h3 style={{textDecoration: 'underline'}}>Our Preferred Lenders</h3>
            <Row>
            {data.map((lender) => {
              return (
                <Col xs="12" md="6" lg="4">
                  <div
                    style={{
                      maxWidth: "23rem",
                      width: "100%",
                      height: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    <CardBody>
                        <CardImg src={lender.image} style={{height: '300px', width: 'auto', objectFit: 'cover'}} />
                      <h3 className="text-black">{lender.name}</h3>
                      <div>
                        <h6 className="text-black">{lender.institution}</h6>
                        <h6 className="text-black">#{lender.nmls}</h6>
                      </div>
                    </CardBody>
                    <CardFooter>
                        <a href={lender.link} target='_blank'>
                            <p style={{color: 'black'}}>Learn More</p>
                        </a>
                    </CardFooter>
                  </div>
                </Col>
              );
            })}
          </Row>
            </> 
            : null}
      </>
  )
}
