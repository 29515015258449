import React, {useState, useEffect} from "react";
import PageLayout from "components/PageLayout/PageLayout";
import { Button, Input } from "reactstrap";

export default function Referrals() {
  const [copied, setCopied] = useState(false);

  // Your realtor's referral link
  const referralLink = "https://cbbelleproperties.com/referrals";

  // Function to copy referral link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    // Reset copied state after 3 seconds
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <PageLayout
      heading={"Refer A Friend"}
      background={
        "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      }
      content={
        <p>
          Thank you for considering referring your friends, family, and
          colleagues to our real estate services. Our team deeply appreciates
          your trust and confidence in us. Referrals are the highest compliment
          we can receive, and we are committed to delivering exceptional service
          to every client you send our way.
          <br />
          <br />
          Your referrals not only help our business grow, but they also allow us
          to continue serving our community with passion and dedication. Whether
          you know someone who is looking to buy, sell, or invest in real
          estate, we are here to provide them with expert guidance and support
          every step of the way.
          <br />
          <br />
          If you have someone in mind who could benefit from our services,
          please don't hesitate to reach out to us. We promise to treat your
          referrals with the same level of care and professionalism that we
          provide to all of our clients.
          <br />
          <br />
          <p>
            If you know someone who could benefit from our real estate services,
            we'd love for you to refer them to us!
          </p>
          <p>Simply share the following link with them:</p>
          <div style={{ alignItems: "center" }} className='mt-3'>
            <Input
              type="text"
              value={referralLink}
              readOnly
              style={{ marginRight: "10px", width: "300px" }}
            />
            <Button onClick={copyToClipboard}>Copy Link</Button>
            {copied && (
              <div>
              <span style={{ marginLeft: "10px", color: "green" }}>
                Copied!
              </span>
              </div>
            )}
          </div>
          <br />
          Once again, thank you for your trust and support. We look forward to
          the opportunity to serve you and your referrals.
        </p>
      }
    />
  );
}
