import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  CardTitle,
  Form,
} from "reactstrap";

const CommissionCalculator = () => {
  const [salePrice, setSalePrice] = useState("");
  const [commissionRate, setCommissionRate] = useState(6);
  const [totalCommission, setTotalCommission] = useState();

  const calculateCommission = (e) => {
    e.preventDefault()
    const commission = (commissionRate / 100) * salePrice;
    setTotalCommission(commission);
  };

  const reset = (e) => {
    e.preventDefault()
    setSalePrice("")
    setCommissionRate(6)
    setTotalCommission()
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h6" style={{fontWeight: 'bold'}}>Commission Calculator</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={calculateCommission}>
        <FormGroup>
          <label htmlFor="salePrice">Sale Price ($):</label>
          <Input
            type="number"
            id="salePrice"
            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="commissionRate">Commission Rate (%):</label>
          <Input
            type="number"
            id="commissionRate"
            value={commissionRate}
            onChange={(e) => setCommissionRate(e.target.value)}
          />
        </FormGroup>
        <Button>Calculate Commission</Button>
        </Form>
        {totalCommission && (
          <div className="mt-2">
            <h5>Total Commission: ${totalCommission}</h5>
            <Button onClick={reset}>Reset</Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default CommissionCalculator;
