import React, { useState } from 'react'
import { Button, Form, FormGroup, Label, Row, Col, Input } from 'reactstrap'

export default function Support() {
    const [issue, setIssue] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            alert('Demo functionality reached!')
        } catch (error) {
            console.log(error)
        } finally{
            setLoading(false)
            setIssue('')
        }
    }

  return (
    <div className="content">
        <div>
            <Row>
                <Col md='4'>
                <div className='mb-3'>
                    <h6>Admin Support</h6>
                    <div>
                    <h2>DDM Web Designs</h2>
                    </div>
                    <p> 
                        Need help with your website?
                        <br />
                        Maybe want custom updates or features?
                        <br />
                        <br />
                        We're here to help!
                    </p>
                </div>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Issue</Label>
                            <Input
                             required
                             name='issue'
                             id='issue'
                             type='textarea'
                             style={{minHeight: '200px', maxHeight: '400px'}}
                             value={issue}
                             onChange={(e) => {setIssue(e.target.value)}}
                             />
                        </FormGroup>
                        <div>
                            <Button type='submit' color="primary" loading={loading}>Submit</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    </div>
  )
}
