import React from 'react'
import ShortenedHeader from 'components/Headers/ShortenedHeader'
import { Container } from 'reactstrap'
import './index.css';
import HomeNav from 'components/Navbars/HomeNav';
import HomeFooter from 'components/Footer/HomeFooter';

export default function PageLayout(props) {
  return (
    <>
    <div>
        <HomeNav />
        <ShortenedHeader heading={props.heading} background={props.background} />
        <div className='page-layout' align='center'>
            <Container>
            {props.content}
            </Container>
        </div>
    </div>
    <HomeFooter />
    </>
  )
}
