import React from 'react'
import {
  Container,
    Navbar,
    NavbarBrand,
    NavLink,
  } from 'reactstrap';
import logo from '../../assets/img/Christol-Britton-white-logo.png';
import kw from '../../assets/img/site-images/kw-logo.png';

export default function HomeFooter() {
  return (
    <Navbar
    className='pt-2 pb-2'
    style={{background: 'black', color: 'white', height: "auto"}}
  >
    <div className='mx-auto'>
      <NavLink>
      <img className='mx-auto pt-1 pb-1' src={kw} style={{height: '55px', width: 'auto', objectFit: 'contain'}} />
      <p>
        <span style={{fontSize: '12px', color: 'white'}}>
          brokered by Keller Williams 
          <br />
          office individually owned and operated
          <br />
          795 Brook Hollow Dr, Shreveport LA 71105
          <br />
          (318) 213-1555
        </span>
      </p>
      </NavLink>
      <NavLink>
      <p style={{color: 'white'}}>NMLS: #0912122250</p>
      </NavLink>
      <div className="copyright">
          <span style={{fontSize: '11px', color: 'grey'}}>© 2024 Created by{" "}
          <a
          style={{textDecoration: 'underline'}}
            href="https://www.ddmwebdesigns.com"
            target="_blank"
          >
            DDM Web Designs
          </a>{" "}
          for expert realtors.
          </span>
        </div>
    </div>
  </Navbar>
  )
}
