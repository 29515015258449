import React, { useState, useEffect } from 'react';
import PropertyForm from 'components/PropertyForm/PropertyForm';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  CardHeader,
  Card,
  CardTitle,
} from 'reactstrap';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function Properties() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const openToggle = () => setIsOpen(!isOpen);

  const [isEditing, setIsEditing] = useState(false);

  const [showPropertyForm, setShowPropertyForm] = useState(false);

  // useEffect function to fetch properties on load or component mount
  useEffect(() => {
    fetchProperties();
  }, []);

  // Function to fetch properties from firebase
  const fetchProperties = async () => {
    setLoading(true);
    try {
      const propertiesCollection = await collection(db, 'properties');
      const querySnapshot = await getDocs(propertiesCollection);

      const propertyData = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Capture the document ID
        ...doc.data(), // Capture the document data
      }));
      setProperties(propertyData);
      console.log(propertyData);
    } catch (error) {
      console.error('Error fetching properties: ', error);
    } finally {
      setLoading(false);
    }
  };


  // Handles property list refresh after adding a new property
  // This function is passed to the PropertyForm component
  const handlePropertyAdded = async () => {
    // Refresh properties after adding a new property
    fetchProperties();
    setShowPropertyForm(false);
  };


  // Handles editing an existing property
  // This is handled in this component and not passed to antoher comonent
  const handleUpdateProperty = async (updatedData) => {
    try {
      await updateDoc(doc(db, 'properties', selectedProperty.id), updatedData);
      console.log('Property updated successfully.');
      // Refresh the properties
      fetchProperties();
      setSelectedProperty(null); // Clear selected property after updating
    } catch (error) {
      console.error('Error updating property: ', error);
    }
  };


  // Handles deleting a property from the firebase database
  // This is handled in this component and not passed to another component
  const handleDeleteProperty = async (propertyIndex) => {
    console.log(propertyIndex);
    const confirmDelete = window.confirm('Are you sure you want to delete this property?');

    if (confirmDelete) {
      try {
        // Convert propertyIndex to a string, assuming it's a number
        const propertyId = String(propertyIndex);

        // Delete the property document from Firestore
        await deleteDoc(doc(db, 'properties', propertyId));

        // Update the state to remove the deleted property
        fetchProperties();
      } catch (error) {
        console.error('Error deleting property: ', error);
      }
    }
  };



  // Handles selecting which property needs to be edited
  // Helper function
  const handleEditProperty = (propertyId) => {
    setShowPropertyForm(true)
    const selectedProperty = properties.find((property) => property.id === propertyId);
    if (selectedProperty) {
      toggleEdit(selectedProperty);
    }
  };


  // Function to toggle the editing state and set the selected property
  const toggleEdit = (property) => {
    setSelectedProperty(property);
    setIsEditing(!isEditing);
  };

  return (
    <div className='content'>
      <Row>
        <Col xs='12'>
          {showPropertyForm ? (
            <Card>
              <CardBody>
              <PropertyForm
              onPropertyAdded={handlePropertyAdded}
              selectedProperty={selectedProperty}
              onFormSubmit={handleUpdateProperty}
              resetSelectedProperty={() => {
                setSelectedProperty(null);
                setShowPropertyForm(false);
              }}
            />
                <Button onClick={() => {
                  setShowPropertyForm(false);
                  setSelectedProperty(null)
                  }}>Cancel</Button>
              </CardBody>
            </Card>
          ) : (
            <>
            <p onClick={() => setShowPropertyForm(true)} style={{textDecoration: 'underline', cursor: 'pointer', width: '110px'}}>Add A Property</p>
                {properties && properties.length > 0 ? (
                  <>
                  <Card>
              <CardHeader>
                <h6>My Properties</h6>
              </CardHeader>
              <CardBody>
                    {properties.map((property, index) => (
                      <Card key={index}>
                        <CardHeader>
                          <h3 className='p-0 mb-0' style={{textDecoration: 'underline'}}>{property.address}</h3>
                        </CardHeader>
                        <CardBody>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Address:</span> {property.address}
                          </p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>City:</span>  {property.city}
                          </p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>State:</span>  {property.state}, {property.zipcode}
                          </p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Price:</span>  ${property.price}
                          </p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Beds:</span>  {property.bedNumber} | <span style={{fontWeight: 'bold'}}>Baths:</span> {property.bathNumber} | <span style={{fontWeight: 'bold'}}>sqft:</span> {property.sqft}
                          </p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Description:</span>  {property.description
                          }</p>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Images:</span> 
                          </p>
                          <div>
                            <Row>
                            {property.images.map((imageUrl, imageIndex) => (
                              <img
                                xs='12'
                                md='4'
                                lg='2'
                                key={imageIndex}
                                alt={`Image ${imageIndex}`}
                                src={imageUrl}
                                style={{ width: '150px', margin: '10px', height: '150px', borderRadius: '0.375rem', objectFit: 'cover' }}
                              />
                            ))}
                            </Row>
                          </div>
                          <Button onClick={() => handleEditProperty(property.id)}>Edit</Button>

                          <i
                            className='tim-icons icon-trash-simple m-2'
                            style={{ fontSize: '1.25rem', color: 'red', cursor: 'pointer' }}
                            onClick={() => handleDeleteProperty(property.id)}
                          ></i>
                        </CardBody>
                      </Card>
                    ))}
                                  </CardBody>
            </Card>
                  </>
                ) : (
                  <div align='center'>
                    {properties.length === 0 && <p style={{color: 'black'}}>No properties currently.</p>}
                  </div>
                )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
