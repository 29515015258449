import React, { useEffect } from "react";
import { CardBody, CardHeader, Container, Row, Col, Button } from "reactstrap";
import { UserAuth } from "contexts/AuthContext";
import { Link } from "react-router-dom";
import Hero from "components/Hero/Hero";
import HomeNav from "components/Navbars/HomeNav";
import PropertyList from "components/PropertyList/PropertyList";
import HomeFooter from "components/Footer/HomeFooter";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAnalytics } from "contexts/AnalyticsContext.js";
import ReviewList from "components/ReviewList/ReviewList";

import "./index.css";
import image from '../../assets/img/site-images/image4.jpg'

export default function Home() {
  const { currentUser } = UserAuth();
  const { logEventAndStoreInFirestore } = useAnalytics();


  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "Home page visited");

    // check to see if user is logged in
    if (currentUser) {
      return;
    }
    logEventAndStoreInFirestore("Home page visited");
  }, []);

  const benefits = [
    {
      benefit: "Personalized Service and Tailored Solutions",
      text: "Dedicated professionals offering personalized service and tailored solutions to meet your unique real estate needs.",
      icon: <i className="tim-icons icon-zoom-split" />,
    },
    {
      benefit: "Premium Listings and Exclusive Opportunities",
      text: "Gain access to premium listings and exclusive opportunities, providing you with a competitive edge in the market.",
      icon: <i className="tim-icons icon-shape-star" />,
    },
    {
      benefit: "Strategic Negotiation Techniques",
      text: "Utilize strategic negotiation techniques to navigate complex transactions and secure favorable terms for your real estate endeavors.",
      icon: <i className="tim-icons icon-single-02" />,
    },
  ];
  

  return (
    <>
      <HomeNav />
      <Hero />
      <Container id="landing-page-body" fluid align='center' className="pb-5">
        <Container className="section-divider" fluid>
          <h2 className="section-divider-text">
          Fostering a streamlined and convenient platform for home seekers, property owners, and renters in pursuit of their ideal home.
          </h2>
        </Container>

        <Container className="section-divider" fluid>
          <Row>
            {benefits.map((e) => {
              return (
                <Col xs="12" lg="4">
                  <div style={{ maxWidth: "35rem" }} className='mx-auto'>
                    <CardHeader style={{background: 'none'}}>
                      <span style={{fontSize: '30px', color: 'black'}}>{e.icon}</span>
                    </CardHeader>
                    <div className="mb-0 pb-0">
                      <h3 className="card-header-text">{e.benefit}</h3>
                    </div>
                    <CardBody className="mt-0 pt-0">{e.text}</CardBody>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>

        <Container className="section" fluid>
          <Row>
            <Col xs="12" md="6" className="p-2">
              <img
                src={image}
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </Col>
            <Col xs="12" md="6">
              <div>
                <p>
                  Welcome to CB Belle Properties, a dedicated team offering a comprehensive range of real estate services tailored to meet your unique needs and objectives. With over a decade of experience successfully helping buyers, sellers, and investors, Christol Britton and her team understands the intricacies of the market and strives to deliver exceptional results.
                  <br />
                  <br />
                  Whether you're buying, selling, renting, or investing in property, CB Belle Properties is here to guide you through every step of the process. Our expertise, combined with personalized service, ensures that your real estate journey is smooth and successful.
                  <br />
                  <br />
                  With an extensive knowledge of the local market and a commitment to client satisfaction, our team of experts will handle all aspects of your real estate transaction with professionalism and integrity. Whether you're a first-time homebuyer, a seasoned investor, or looking to sell your property, CB Belle Properties is dedicated to helping you make informed decisions that will benefit you now and in the future.
                </p>
                <div className="mt-5">
                  <Link to="/services">
                    <p className="page-link p-0" style={{border: 'none'}}>Learn More</p>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="section-divider" fluid>
          <h2 className="section-divider-text">
            Crafting the ideal real estate solution tailored to your specific objectives and aspirations.
          </h2>
        </Container>

        <Container className="section" fluid>
          <PropertyList />
        </Container>

        <Container className="section-divider" fluid>
          <h2 className="section-divider-text">
            Delivering transformative experiences that positively impact all our clients' lives.
          </h2>
        </Container>

        <Container className="section" fluid>
          <ReviewList />
        </Container>
      </Container>

      <div align='center'>
          <div id="outro-section">
            <div id="outro-section-overlay">
              <Container>
                <h2 id="outro-section-text">Ready to get started?</h2>
                <Link to="/contact">
                  <Button>Get Started</Button>
                </Link>
              </Container>
            </div>
          </div>
        </div>
      <HomeFooter />
    </>
  );
}
