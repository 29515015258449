import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  NavLink,
  Container,
} from 'reactstrap';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import logo from '../../assets/img/CB-Belle-Logo-White.png'

import './nav.css';

function HomeNav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{background: 'black'}} className='w-100 pt-2 pb-2'>
    <Navbar style={{background: 'black'}} collapseOnSelect expand="lg" className="bg-body-tertiary pt-2 pb-2 pr-0 pl-0">
      <Container fluid className='p-0'>
        <div style={{marginRight: 'auto'}}>
          <Link className='nav-link' to='/'>
            <img src={logo} style={{height: '65px', width: 'auto', objectFit: 'contain'}} />
          </Link>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <i className='tim-icons icon-align-center' style={{color: 'white'}} />
        </Navbar.Toggle>
        <Navbar.Collapse>
        <Nav navbar-nav className='ml-auto pr-5 pl-5'>
          <NavLink>
            <Link to="/" style={{color: 'white'}} className='nav-link'>Home</Link>
          </NavLink>
          <NavLink>
            <Link to="/about" style={{color: 'white'}} className='nav-link'>
              About
            </Link>
          </NavLink>
          <NavLink>
            <Link to="/services" style={{color: 'white'}} className='nav-link'>
              Services
            </Link>
          </NavLink>
          <NavLink>
            <Link to="/properties" style={{color: 'white'}} className='nav-link'>
              Properties
            </Link>
          </NavLink>
          <NavLink>
            <Link to="/reviews" style={{color: 'white'}} className='nav-link'>
              Reviews
            </Link>
          </NavLink>
          <NavLink>
            <NavDropdown style={{background:'black'}} title="Resources" id="collapsible-nav-dropdown">
            <NavDropdown.Item>
                <Link to="/resources/other-services" className='nav-link'>
                Other Services
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/resources/rentals" className='nav-link'>
                Rentals
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/resources/preferred-lenders" className='nav-link'>
                Lenders
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/resources/guides" className='nav-link'>
                Guides
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/referrals" className='nav-link'>
                Referrals
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Link to="/resources" className='nav-link'>
                  All
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            </NavLink>
          <NavLink>
            <Link to="/contact" style={{color: 'white'}} className='nav-link'>
              Contact
            </Link>
          </NavLink>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default HomeNav;