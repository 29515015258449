import React, { useState, useEffect } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import { CardBody, Row, Col } from 'reactstrap';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase.js';
import './index.css';
import { Link } from 'react-router-dom';

export default function Properties() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('dateAdded'); // Default sort by dateAdded

    useEffect(() => {
        fetchData();
    }, [sortBy]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const propertiesCollectionRef = collection(db, 'properties');
            const querySnapshot = await getDocs(
                query(propertiesCollectionRef, orderBy(sortBy, sortBy === 'price' ? 'desc' : 'asc'))
            );
            const propertiesData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

             // Sort properties data based on the selected sorting criteria
             propertiesData.sort((a, b) => {
                switch (sortBy) {
                    case 'price':
                        return b.price - a.price;
                    case 'bedNumber':
                        return b.bedNumber - a.bedNumber;
                    case 'bathNumber':
                        return b.bathNumber - a.bathNumber;
                    case 'sqft':
                        return b.sqft - a.sqft;
                    default:
                        // Sort by most recent dateAdded
                        return new Date(b.dateAdded) - new Date(a.dateAdded);
                }
            });

            setData(propertiesData);
        } catch (error) {
            console.error('Error fetching properties:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    return (
        <PageLayout
            heading="Current Properties"
            background="https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            content={
                <>
                    {loading && !data ? <p>Loading...</p> : null}
                    {!loading && !data ? <p>Currently, we have no properties to display.</p> : null}
                    {!loading && data.length > 0 ? (
                        <>
                            <p>View our current properties that we are managing.</p>
                            <br />
                            <div className="sort-dropdown">
                                <label htmlFor="sort">Sort by:</label>
                                <br />
                                <select id="sort" value={sortBy} onChange={handleSortChange}>
                                    <option value="dateAdded">Recently Added</option>
                                    <option value="price">Price (Highest to Lowest)</option>
                                    <option value="bedNumber">Number of Bedrooms</option>
                                    <option value="bathNumber">Number of Bathrooms</option>
                                    <option value="sqft">Square Footage</option>
                                </select>
                            </div>
                            <Row>
                                {data.map((property) => (
                                    <Col xs="12" md="6" lg="4" key={property.id}>
                                        <div style={{ width: '100%', maxWidth: '400px', overflowY: 'hidden' }}>
                                            <Link to={`/properties/${property.address}`}>
                                                <CardBody className="pb-1">
                                                    <img
                                                        src={property.images[0]}
                                                        style={{
                                                            width: '100%',
                                                            height: '300px',
                                                            objectFit: 'cover',
                                                            borderRadius: '0.375rem',
                                                        }}
                                                        alt="Property"
                                                    />
                                                    <div className="mb-1">
                                                        <h4 className="mb-0">
                                                            <span className="text-black">{property.address}</span>
                                                        </h4>
                                                        <h6>
                                                            <span className="text-black">
                                                                {property.city} {property.state}
                                                            </span>
                                                        </h6>
                                                        <br />
                                                        <h6>
                                                            <span className="text-black">${property.price}</span>
                                                        </h6>
                                                        <h6>
                                                            <span style={{fontWeight: 'bold'}}>Bed:</span> {property.bedNumber} | <span style={{fontWeight: 'bold'}}>Bath:</span> {property.bathNumber} | <span style={{fontWeight: 'bold'}}>SQFT:</span> {property.sqft}
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span className="text-black">
                                                                {property.description.length > 100
                                                                    ? property.description.substring(0, 100) + '...'
                                                                    : property.description}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </CardBody>
                                            </Link>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    ) : (
                        <p>Currently, we have no properties to display.</p>
                    )}
                </>
            }
        />
    );
}
