import React, { useState, useEffect } from "react";
import PageLayout from "components/PageLayout/PageLayout";
import { Link, useParams } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Card, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase.js";
import "./index.css";

export default function Properties() {
  const { address, id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const toggleModal = (image) => {
    setSelectedImage(image);
    setModal(!modal);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const blogsCollectionRef = collection(db, "properties");
      const querySnapshot = await getDocs(
        query(blogsCollectionRef, where("address", "==", address))
      );

      if (querySnapshot.docs.length === 1) {
        const docSnap = querySnapshot.docs[0];
        console.log("Document data:", docSnap.data());
        setData(docSnap.data());
      } else {
        // Handle case when no or multiple documents are found for the given address
        console.log("No or multiple documents found for the given address.");
        // You might want to redirect or show an error message to the user.
      }
    } catch (error) {
      console.log("There has been an error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageLayout
        heading={data ? data.address : null}
        background={
          data && data.images.length > 0
            ? data.images[0]
            : "https://images.unsplash.com/photo-1508691067518-cc25e8ad5cc3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
        content={
          <>
            {loading && !data ? (
              <>
                <p>Loading...</p>
              </>
            ) : null}
            {!loading && !data ? (
              <>
                <p>
                  Sorry, but we can't find the property you're looking for...
                </p>
                <br />
                <Link to="/properties" className="mt-2">
                  <p>Return To Properties</p>
                </Link>
              </>
            ) : null}
            {!loading && data ? (
              <>
                <div>
                  <p>
                    <span style={{fontWeight: 'bold'}}>Description:</span>
                    <br />
                    {data.description}</p>
                </div>
                <div className="mt-2">
                  
                  <p>
                    <span style={{fontWeight: 'bold'}}>Location:</span>
                    <br /> 
                    {data.city} {data.state}, {data.zipcode}
                  </p>
                  <p>
                    <span style={{fontWeight: 'bold'}}>Bed:</span> {data.bedNumber} | <span style={{fontWeight: 'bold'}}>Bath:</span> {data.bathNumber} | <span style={{fontWeight: 'bold'}}>SQFT:</span> {data.sqft}
                  </p>
                  <p>
                    <span style={{fontWeight: 'bold'}}>Price:</span>
                    <br />
                   ${data.price}
                  </p>
                </div>
                <div className="mt-5">
                  <p>
                    <span style={{fontWeight: 'bold'}}>Gallery:</span>
                  </p>
                  {data && data.images.length > 0 ? (
                    <>
                      <Row>
                        {data.images.map((image, index) => {
                          return (
                            <Col xs="12" md="6" lg="4" className="p-2" key={index}>
                              <img
                                src={image}
                                style={{
                                  height: "300px",
                                  width: "100%",
                                  borderRadius: "0.375rem",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleModal(image)}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  ) : (
                    <p className="mt-5">
                      <span className="text-black">
                        No images currently available.
                      </span>
                    </p>
                  )}
                  <div className="mt-5">
                    <Link to="/properties">
                      <p id='return-link'>Return To Properties</p>
                    </Link>
                  </div>
                </div>
              </>
            ) : null}
          </>
        }
      />
      <Modal isOpen={modal} toggle={toggleModal} size='lg'>

        <ModalBody>
          <img src={selectedImage} style={{ width: "100%", height: '500px', objectFit: 'cover'}} alt="Property" />
        </ModalBody>
        <ModalFooter>
          <Button className="mx-auto" color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
