import React, {useState, useEffect} from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    CardHeader,
    Card,
    CardTitle,
    CardImg,
    FormGroup,
    Label,
    Form,
    Input,
    Alert
} from 'reactstrap';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';

export default function Resources() {
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        link: '',
    });

    const [showForm, setShowForm] = useState(false);

        // Function to fetch lenders from firebase
    const fetchResources = async () => {
        setLoading(true);
        try {
            const resourcesCollection = await collection(db, 'resources');
            const querySnapshot = await getDocs(resourcesCollection);

            const resourcesData = querySnapshot.docs.map((doc) => ({
                id: doc.id, // Capture the document ID
                ...doc.data(), // Capture the document data
            }));
            setResources(resourcesData);
            console.log(resourcesData);
        } catch (error) {
            console.error('Error fetching lenders: ', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle form submission for adding or updating a lender
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!formData.name || !formData.description || !formData.link) {
            setMessage('All fields required.');
            return;
        }
    
        setLoading(true);
        
        if(selectedResource){
            console.log(selectedResource)
        }

        try {
            let resourceData;
            const file = formData.link;
    
            // Upload file to Firebase Storage
            const fileName = `${file.name}`;
            const storageRef = ref(storage, `resources/${fileName}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
    
            if (selectedResource) {
                // Update existing resource
                resourceData = {
                    name: formData.name,
                    description: formData.description,
                    link: downloadURL
                };
    
                // Construct Firestore document reference for the selected resource
                const resourceRef = doc(db, 'resources', selectedResource);
                await updateDoc(resourceRef, resourceData); // Update the document with new data
                setMessage('Resource successfully updated');
                fetchResources()
            } else {
                // Add new resource
                resourceData = {
                    name: formData.name,
                    description: formData.description,
                    link: downloadURL,
                };
    
                // Add new resource document to Firestore collection
                await addDoc(collection(db, 'resources'), resourceData);
                setMessage('Resource successfully added');
            }
    
            // Refresh resources data
            fetchResources();
        } catch (error) {
            console.error('Error submitting resource: ', error);
            setMessage('There has been an error...please try again.');
        } finally {
            setLoading(false);
            setFormData({
                name: '',
                description: '',
                link: '',
            });
            setSelectedResource(null)
            setShowForm(false);
            handleMessageReset();
        }
    };
    

    // Function to reset message after delay
    const handleMessageReset = () => {
        setTimeout(() => {
            setMessage('');
        }, 2500);
    };

    // Function to handle editing selection
    const handleEditSelection = async (id) => {
        setShowForm(true);
        setSelectedResource(id);
        const selectedResourceData = resources.find((resource) => resource.id === id);
        setFormData({
            name: selectedResourceData.name,
            description: selectedResourceData.description,
            link: selectedResourceData.link,
        });
    };

    // Function to handle deleting a lender
    const handleDeleteResource = async (id) => {
        setLoading(true);
        
        const confirmDelete = window.confirm(
          "Are you sure you want to delete this resource?"
        );
      
        if(!confirmDelete){
          return
        }
        
        try {
            // Construct Firestore document reference for the selected lender
            const resourceRef = doc(db, 'resources', id);
            await deleteDoc(resourceRef); // Delete the document
            setMessage('Lender successfully deleted');
            // Refresh lenders data
            fetchResources();
        } catch (error) {
            console.error('Error deleting resource: ', error);
            setMessage('There has been an error...please try again.');
        } finally {
            setLoading(false);
            handleMessageReset();
        }
      };

       // Function to toggle form visibility and reset form data
    const toggle = () => {
        setShowForm(!showForm);
        setFormData({
            name: '',
            description: '',
            link: '',
        });
        setSelectedResource(null)
    };

    // Fetch lenders data on component mount
    useEffect(() => {
        fetchResources();
    }, []);

  return (
    <div className='content'>
 <Row>
                <Col xs='12'>
                    <p
                        onClick={() => setShowForm(true)}
                        style={{ textDecoration: 'underline', cursor: 'pointer', width: '110px' }}
                    >
                        Add A Resource
                    </p>
                    {message && <Alert>{message}</Alert>}
                    {!showForm ? (
                        <>
                            {resources && resources.length > 0 ? (
                                <>
                                    <Card>
                                        <CardHeader>
                                            <h6>My Resources</h6>
                                        </CardHeader>
                                        <CardBody>
                                            {resources.map((resource, index) => (
                                                <Card key={index}>
                                                    <CardHeader>
                                                        <h3 className='p-0 mb-0' style={{ textDecoration: 'underline' }}>
                                                            {resource.name}
                                                        </h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <p>
                                                            <span style={{ fontWeight: 'bold' }}>Description:</span> {resource.description}
                                                        </p>
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 'bold' }}>Resource Link: </span>
                                                                <a href={resource.link} target='_blank' style={{ color: 'black', textDecoration: 'underline' }}>
                                                                    View Here
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <Button onClick={() => handleEditSelection(resource.id)}>Edit</Button>
                                                        <i
                                                            className='tim-icons icon-trash-simple m-2'
                                                            style={{ fontSize: '1.25rem', color: 'red', cursor: 'pointer' }}
                                                            onClick={() => handleDeleteResource(resource.id)}
                                                        ></i>
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </>
                            ) : (
                                <div align='center'>
                                    {resources.length === 0 && <p style={{ color: 'black' }}>No lenders currently.</p>}
                                </div>
                            )}
                        </>
                    ) : (
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label>Resource Name</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='resourceNameInput'
                                            name='name'
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Resource Description</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='resourceDescriptionInput'
                                            name='description'
                                            value={formData.description}
                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Resource Link</Label>
                                        <Input
                                            type='file'
                                            name='link'
                                            id='resourceLinkInput'
                                            onChange={(e) => setFormData({ ...formData, link: e.target.files[0] })}
                                            accept='.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                            />
                                    </FormGroup>
                                    <Button onClick={toggle} color='secondary' variant='secondary'>
                                        Cancel
                                    </Button>
                                    <Button type='submit' color='primary' disabled={loading}>
                                        Submit
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </div>
    );
}