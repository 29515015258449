import { useContext, createContext, useState, useEffect } from "react";

const DataContext = createContext(null);

export function DataContextProvider({ children }) {
    const [data, setData] = useState([])

    useEffect(() => {
        console.log('Fake buyer data:', fakeBuyerData, 'Fake seller data:', fakeSellerData,)
    }, [])

    // Fake Buyer Data || TO BE REPLACED WITH GOOGLE FIREBASE ACCOUNT DATA W/ USE EFFECT ON LOAD
    const fakeBuyerData = [
        {
            "firstName": "John",
            "lastName": "Hawkins",
            "age": "39",
            "address": {
                "street": "123 Main St",
                "city": "Shreveport", 
                "state": "LA",
                "zip_code": "71101"
            },
            "budget": "350,000",
            "status": "Just looking.",
            "areas_of_interest": [
                {
                    "neighborhood": "Highland",
                    "city": "Shreveport",
                    "state": "LA",
                    "zip_code": "70022"
                },
                {
                    "neighborhood": "Acre Ridge",
                    "city": "Shreveport",
                    "state": "LA",
                    "zip_code": "70021"
                },
                {
                    "neighborhood": "Elevens Highhills",
                    "city": "Bossier",
                    "state": "LA",
                    "zip_code": "70332"
                },
                {
                    "neighborhood": "Ellis Crest",
                    "city": "Bossier",
                    "state": "LA",
                    "zip_code": "70342"
                },
            ],
            "chosen_houses": [
                {
                    "street": "123 North St",
                    "city": "Shreveport", 
                    "state": "LA",
                    "zip_code": "71101",
                    "price": "342,000"  
                },
                {
                    "street": "123 South St",
                    "city": "Shreveport", 
                    "state": "LA",
                    "zip_code": "71102",
                    "price": "321,000" 
                },
                {
                    "street": "123 SE St",
                    "city": "Shreveport", 
                    "state": "LA",
                    "zip_code": "71105",
                    "price": "299,999" 
                }
            ],
            "transaction_type": "FHA",
            "buyer_type": "Single Family",
        },
        {
            "firstName": "Sally",
            "lastName": "Evenings",
            "age": "29",
            "address": {
                "street": "345 Main St",
                "city": "Josevile", 
                "state": "LA",
                "zip_code": "41101"
            },
            "budget": "120,000",
            "status": "Actively Buying.",
            "areas_of_interest": [
                {
                    "neighborhood": "Acrids",
                    "city": "Josevile",
                    "state": "LA",
                    "zip_code": "40022"
                },
                {
                    "neighborhood": "Sacre Ridgeback",
                    "city": "Josevile",
                    "state": "LA",
                    "zip_code": "40021"
                },
                {
                    "neighborhood": "The High Hills",
                    "city": "Josevile",
                    "state": "LA",
                    "zip_code": "40332"
                },
            ],
            "chosen_houses": [
                {
                    "street": "321 North St",
                    "city": "Bossier", 
                    "state": "LA",
                    "zip_code": "71101",
                    "price": "89,000"  
                },
                {
                    "street": "321 South St",
                    "city": "Bossier", 
                    "state": "LA",
                    "zip_code": "71102",
                    "price": "91,000" 
                },
                {
                    "street": "321 SE St",
                    "city": "Bossier", 
                    "state": "LA",
                    "zip_code": "71105",
                    "price": "109,999" 
                }
            ],
            "transaction_type": "Hard Money Lender",
            "buyer_type": "Investor",
        },
    ]

    // Fake SELLER Data || TO BE REPLACED WITH GOOGLE FIREBASE ACCOUNT DATA W/ USE EFFECT ON LOAD
    const fakeSellerData = [
        {
          "firstName": "Anthony",
          "lastName": "Williams",
          "age": "39",
          "address": {
            "street": "1343 Mainion Dr",
            "city": "Shreveport", 
            "state": "LA",
            "zip_code": "71102"
          },
          "properties_to_sell": [
            {
              "street": "1123 Maans Cir.",
              "city": "Shreveport", 
              "state": "LA",
              "zip_code": "71334",
              "contract_status": "Under Contract",
              "current_value": "200,000",
              "estimated_arv_value": "200,000",
              "list_price": "225,000",
            },
          ],
          "property_listings": [
            {
              "street": "1123 Maans Cir.",
              "city": "Shreveport", 
              "state": "LA",
              "zip_code": "71334",
              "contract_status": "Under Contract",
              "current_value": "200,000",
              "estimated_arv_value": "200,000",
              "list_price": "225,000",
            },
          ],
          "seller_type": "Individual Seller",
          "listing_agent": "Jane Doe",
          "listing_agent_contact": "jane@example.com",
        },
        {
          "firstName": "Alesix",
          "lastName": "Avansio",
          "age": "32",
          "address": {
            "street": "133 Sansion Dr",
            "city": "Blue Mountain", 
            "state": "TX",
            "zip_code": "71102"
          },
          "properties_to_sell": [
            {
              "street": "113 Theris Cir.",
              "city": "Haughton", 
              "state": "LA",
              "zip_code": "71434",
              "contract_status": "Pending",
              "current_value": "100,000",
              "estimated_arv_value": "200,000",
              "list_price": "150,000",
            },
            {
              "street": "3343 Elington.",
              "city": "Haughton", 
              "state": "LA",
              "zip_code": "71454",
              "contract_status": "Listed",
              "current_value": "400,000",
              "estimated_arv_value": "400,000",
              "list_price": "425,000",
            },
          ],
          "property_listings": [
            {
              "street": "113 Theris Cir.",
              "city": "Haughton", 
              "state": "LA",
              "zip_code": "71434",
              "contract_status": "Pending",
              "current_value": "100,000",
              "estimated_arv_value": "200,000",
              "list_price": "150,000",
            },
            {
              "street": "3343 Elington.",
              "city": "Haughton", 
              "state": "LA",
              "zip_code": "71454",
              "contract_status": "Listed",
              "current_value": "400,000",
              "estimated_arv_value": "400,000",
              "list_price": "425,000",
            },
          ],
          "seller_type": "Individual Seller",
          "listing_agent": "John Smith",
          "listing_agent_contact": "john@example.com",
        },
      ];

    return(
        <DataContext.Provider value={{
            data,
            fakeBuyerData,
            fakeSellerData,
        }}>{children}</DataContext.Provider>
    )
}

export const DataContextData = () => {
    return useContext(DataContext)
}