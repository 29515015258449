import React, { createContext, useContext, useEffect, useState } from "react";
import { getAnalytics } from "firebase/analytics";
import { getDocs, collection, addDoc, where, } from "firebase/firestore";
import { db } from "../firebase.js" // Import your Firebase configuration

const AnalyticsContext = createContext();


export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const AnalyticsProvider = ({ children }) => {
  const [events, setEvents] = useState([])
    
  const analytics = getAnalytics();

  useEffect(() => {
    fetchEventData()
    console.log(events)
  }, [events.length === 0 || !events])

  const logEventAndStoreInFirestore = async (eventName) => {
    try {
      // Log event with Firebase Analytics (using modular API)
      // Comment out the following line if you don't want to log the event for analytics
      // import { logEvent } from "firebase/analytics";
      // logEvent(analytics, eventName);
  
      // Store event in Firestore collection
      const eventsCollection = collection(db, "events");
      const eventDocRef = await addDoc(eventsCollection, {
        eventName: eventName,
        timestamp: new Date(),
      });
  
      console.log("Event logged and stored in Firestore with ID:", eventDocRef.id);
    } catch (error) {
      console.error("Error logging event and storing in Firestore:", error);
    }
  };

  const fetchEventData = async () => {

    try {
        const eventsCollectionRef = await collection(db, 'events');
        getDocs(eventsCollectionRef)
          .then((querySnapshot) => {
            const eventsData = querySnapshot.docs.map((doc) => doc.data());
            setEvents(eventsData)
            console.log(events)
          })
          .catch((error) => {
            console.error(error.message);
          });
      } catch (error) {
        console.error(error.message);
      }
  };

  return (
    <AnalyticsContext.Provider
      value={{ logEventAndStoreInFirestore, fetchEventData, events }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
