import React, { useState } from 'react';
import { useAnalytics } from 'contexts/AnalyticsContext';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

const MyAnalytics = () => {
  const { events } = useAnalytics();
  const [activeTab, setActiveTab] = useState('currentDay');

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getCurrentDayEvents = () => {
    const currentDate = new Date();
    return events.filter(event => isSameDay(event.timestamp, currentDate));
  };

  const getPreviousDayEvents = () => {
    const previousDate = new Date();
    previousDate.setDate(previousDate.getDate() - 1);
    return events.filter(event => isSameDay(event.timestamp, previousDate));
  };

  const getCurrentWeekEvents = () => {
    const currentDate = new Date();
    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() - currentDate.getDay());
    const weekEnd = new Date(currentDate);
    weekEnd.setDate(weekStart.getDate() + 6);
    return events.filter(event => event.timestamp.toDate() >= weekStart && event.timestamp.toDate() <= weekEnd);
  };

  const getCurrentMonthEvents = () => {
    const currentDate = new Date();
    const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return events.filter(event => event.timestamp.toDate() >= monthStart && event.timestamp.toDate() <= monthEnd);
  };

  const getCurrentYearEvents = () => {
    const currentDate = new Date();
    const yearStart = new Date(currentDate.getFullYear(), 0, 1);
    const yearEnd = new Date(currentDate.getFullYear(), 11, 31);
    return events.filter(event => event.timestamp.toDate() >= yearStart && event.timestamp.toDate() <= yearEnd);
  };

  const isSameDay = (timestamp, date) => {
    return (
      timestamp.toDate().getFullYear() === date.getFullYear() &&
      timestamp.toDate().getMonth() === date.getMonth() &&
      timestamp.toDate().getDate() === date.getDate()
    );
  };

  return (
    <div>
        <Card>
      <CardHeader>
        <h6 className="title d-inline" style={{fontWeight: 'bold'}}>Site Analytics</h6>
      <Nav>
        <NavItem className='m-1'>
          <p
            className={activeTab === 'currentDay' ? 'active' : ''}
            onClick={() => toggleTab('currentDay')}
            style={{backgroundColor: 'transparent', cursor: 'pointer'}}
          >
            Current Day
          </p>
          </NavItem>
        <NavItem className='m-1'>
          <p
            className={activeTab === 'previousDay' ? 'active' : ''}
            onClick={() => toggleTab('previousDay')}
            style={{backgroundColor: 'transparent'}}
          >
           Previous Day
          </p>
        </NavItem>
        <NavItem className='m-1'>
          <p
            className={activeTab === 'currentWeek' ? 'active' : ''}
            onClick={() => toggleTab('currentWeek')}
            style={{backgroundColor: 'transparent'}}
          >
            Current Week
          </p>
        </NavItem>
        <NavItem className='m-1'>
          <p
            className={activeTab === 'currentMonth' ? 'active' : ''}
            onClick={() => toggleTab('currentMonth')}
            style={{backgroundColor: 'transparent'}}
          >
            Current Month
          </p>
        </NavItem>
        <NavItem className='m-1'>
          <p
            className={activeTab === 'currentYear' ? 'active' : ''}
            onClick={() => toggleTab('currentYear')}
            style={{backgroundColor: 'transparent'}}
          >
            Current Year
          </p>
        </NavItem>
      </Nav>
      </CardHeader>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="currentDay">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Site Visitors Today:</h5>
              <CardTitle tag="h3">
                <i className="tim-icons icon-bell-55 text-info" />{' '}
                {getCurrentDayEvents().length}
              </CardTitle>
            </CardHeader>
          </Card>
        </TabPane>
        <TabPane tabId="previousDay">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Site Visitors Yesterday:</h5>
              <CardTitle tag="h3">
                <i className="tim-icons icon-bell-55 text-info" />{' '}
                {getPreviousDayEvents().length}
              </CardTitle>
            </CardHeader>
          </Card>
        </TabPane>
        <TabPane tabId="currentWeek">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Site Visitors This Week:</h5>
              <CardTitle tag="h3">
                <i className="tim-icons icon-bell-55 text-info" />{' '}
                {getCurrentWeekEvents().length}
              </CardTitle>
            </CardHeader>
          </Card>
        </TabPane>
        <TabPane tabId="currentMonth">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Site Visitors This Month:</h5>
              <CardTitle tag="h3">
                <i className="tim-icons icon-bell-55 text-info" />{' '}
                {getCurrentMonthEvents().length}
              </CardTitle>
            </CardHeader>
          </Card>
        </TabPane>
        <TabPane tabId="currentYear">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Site Visitors This Year:</h5>
              <CardTitle tag="h3">
                <i className="tim-icons icon-bell-55 text-info" />{' '}
                {getCurrentYearEvents().length}
              </CardTitle>
            </CardHeader>
          </Card>
        </TabPane>
      </TabContent>
      </Card>
    </div>
  );
};

export default MyAnalytics;

