import React from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

export default function Services() {
    return (
        <PageLayout 
            heading='Our Services' 
            background={'https://images.unsplash.com/photo-1523217582562-09d0def993a6?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
            content={
                    <>
                    <div>
                    <p className='text-black'>We offer a comprehensive range of services to meet all your real estate needs. Whether you're buying, selling, or investing, our dedicated team is here to provide you with expert guidance and support every step of the way.</p>
                    <br />

                    <h3 className='text-black'>Buyer Services</h3>
                    <p className='text-black'>Looking for your dream home? Our experienced agents will work tirelessly to help you find the perfect property that fits your needs and budget. From conducting personalized property searches to negotiating offers, we're committed to making your home-buying journey as smooth and stress-free as possible.</p>
                    <br />

                    <h3 className='text-black'>Seller Services</h3>
                    <p className='text-black'>Ready to sell your property? Let us help you navigate the selling process with ease. We'll create a customized marketing plan to showcase your home to potential buyers, conduct market analysis to determine the optimal listing price, and negotiate offers on your behalf to ensure you get the best possible outcome.</p>
                    <br />

                    <h3 className='text-black'>Consultation Services</h3>
                    <p className='text-black'>Have questions about the real estate market or need advice on a specific real estate matter? Our experienced agents are here to help. We offer personalized consultation services to address your concerns and provide you with expert guidance and insights tailored to your unique situation.</p>
                    <br />

                    <p className='text-black'>Whatever your real estate needs may be, you can count on Christol Britton and her team to deliver exceptional service and results. Contact us today to learn more about how we can help you achieve your real estate goals.</p>
                    </div>
                    <div className='mt-5'>
                        <Link to='/contact'>
                            <Button>Contact Us Now</Button>
                        </Link>
                    </div>
                    </>
            } 
        />
      )
}
