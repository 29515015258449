import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import ContactImporter from "components/ContactImporter/ContactImporter";
import { db } from "../firebase"; // Import your Firebase configuration
import { setDoc } from "firebase/firestore";
import { Card, CardBody, Table, Button, CardHeader, Row, Col, Alert, Input, CardFooter, Form, Label, Container } from "reactstrap";
import EditContactModal from "components/EditContactModal/EditContactModal";

export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleContact, setSingleContact] = useState({ name: '', email: '', phone: null, occupation: '' });
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(false)
  const [isEditFormVisible, setIsEditFormVisible] = useState(false); // New state variable to track the visibility of the edit form
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    fetchContactsData();
  }, []);

  const fetchContactsData = async () => {
    try {
      const contactsCollectionRef = collection(db, "contacts");
      const querySnapshot = await getDocs(contactsCollectionRef);

      const contactsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(contactsData);
      setContacts(contactsData);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleContactAdded = () => {
    fetchContactsData(); // Call the function to refresh contacts data
  };

  const toggleEditForm = () => {
    setIsEditFormVisible(!isEditFormVisible);
  };

  const handleEditContact = (contactEmail) => {
    const selectedContact = contacts.find(
      (contact) => contact.email === contactEmail
    );
    if (selectedContact) {
      console.log(selectedContact);
      setSelectedContact(selectedContact);
      toggleEditForm();
    }
  };

  const handleUpdateContact = async (updatedContact) => {
    try {
      const contactRef = doc(db, "contacts", updatedContact.id);
      await updateDoc(contactRef, updatedContact);
      fetchContactsData(); // Refresh the contacts after update
      setIsEditFormVisible(false); // Reset the edit form visibility
    } catch (error) {
      console.error("Error updating contact:", error);
    }
  };

  const handleUpdateCancel = () => {
    setIsEditFormVisible(false)
  }

  const handleContactDelete = async (contactId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this contact?"
    );

    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "contacts", contactId));
        fetchContactsData(); // Refresh the contacts after deletion
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  };

  // Contact add functionality

  const saveContactToFirebase = async (e) => {
    e.preventDefault();

    if(!singleContact.name, !singleContact.email, !singleContact.phone, !singleContact.occupation){
      alert('Please add all fields.');
      return
    }
    setLoading(true);

    try {
      const contactDoc = doc(db, 'contacts', singleContact.email);
      await setDoc(contactDoc, singleContact, { merge: true });
      setMessage('Contact added successfully!');
      
      clearContacts()
      setShowForm(false)
      fetchContactsData()
    } catch (error) {
      setMessage('There was an error! Try again.');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setMessage('');
      }, 2500);
    }
  };

  const clearContacts = () => {
    setSingleContact({
      name: '',
      email: '',
      phone: null,
      occupation: ''
    })
  };

  const toggle = () => { 
    setShowForm(!showForm); 
    clearContacts(); 
  }

  // Function to handle search input change
const handleSearchInputChange = (e) => {
  setSearchQuery(e.target.value);
};

// Filtered contacts based on the search query
const filteredContacts = contacts.filter(contact =>
  contact.name.toLowerCase().includes(searchQuery.toLowerCase())
);

  return (






<div className="content">
<div className="d-flex">
        <p onClick={toggle} className='pr-2'>
          <span style={{ textDecoration: 'underline' }}>Add Contact</span>
        </p>
        {/*}
        <ContactImporter onContactAdded={handleContactAdded} />
        {*/}
      </div>
      {message && <Alert color={message.includes('error') ? 'danger' : 'success'}>{message}</Alert>}      
      {showForm ? (
        // Render the add contact form
        // This section is the same as your current add contact form
        <Card>
          <Form onSubmit={saveContactToFirebase}>
            <CardHeader toggle={toggle}>Add Single Contact</CardHeader>
            <CardBody>
              
              <Row>
                <Col md='4'>
                  <Label>Name</Label>
                  <Input
                    required
                    style={{ color: 'black' }}
                    className='mt1- mb-1'
                    type="text"
                    placeholder="Name"
                    value={singleContact.name}
                    onChange={(e) => setSingleContact({ ...singleContact, name: e.target.value })}
                  />
                </Col>
              </Row>

              <Row>
                <Col md='4'>
                  <Label>Occupation</Label>
                  <Input
                    required
                    style={{ color: 'black' }}
                    className='mt1- mb-1'
                    type="text"
                    placeholder="Occupation"
                    value={singleContact.occupation}
                    onChange={(e) => setSingleContact({ ...singleContact, occupation: e.target.value })}
                  />
                </Col>
              </Row>

              <Row>
                <Col md='4'>
                  <Label>Email</Label>
                  <Input
                    required
                    style={{ color: 'black' }}
                    className='mt1- mb-1'
                    type="email"
                    placeholder="Email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" // Email pattern validation
                    value={singleContact.email}
                    onChange={(e) => setSingleContact({ ...singleContact, email: e.target.value })}
                  />
                </Col>
              </Row>

              <Row>
                <Col md='3'>
                  <Label>Phone</Label>
                  <Input
                    required
                    style={{ color: 'black' }}
                    className='mt1- mb-1'
                    type="tel"
                    placeholder="Phone # (e.g., (123) 456-7890)"
                    pattern="\(\d{3}\) \d{3}-\d{4}" // Enforce format (###) ###-####
                    maxLength="14" // Maximum characters for format (###) ###-####
                    value={singleContact.phone}
                    onChange={(e) => setSingleContact({ ...singleContact, phone: e.target.value })}
                  />
                  <p style={{fontSize: '11px', color: 'grey'}}>* Required format: (123) 456-7890</p>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>{' '}
              <Button color="primary" type="submit" disabled={loading}>
                Save
              </Button>
            </CardFooter>
            </Form>
          </Card>
      ) : isEditFormVisible ? (
        // Render the edit contact form
        <EditContactModal
          contact={selectedContact}
          onUpdate={handleUpdateContact}
          onCancel={handleUpdateCancel}
        />
      ) : (
        // Render the list of contacts
        <>
          {contacts.length > 0 ? (
            <>
              <Card className="card-tasks">
                <CardHeader>
                  <h6>My Contacts</h6>
                  <Row className="mb-3">
  <Col md="3">
    <Input
      type="text"
      placeholder="Search by name"
      value={searchQuery}
      onChange={handleSearchInputChange}
    />
  </Col>
</Row>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Name</th>
                          <th>Occupation</th>
                          <th>Email</th>
                          <th>Phone #</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {searchQuery ? <>
                      <p>Search Result:</p>
              {filteredContacts.map((contact) => (
                // Render your contact information here
                <tr key={contact.id}>
                            <td>{contact.name}</td>
                            <td>{contact.occupation}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phone}</td>
                            <td className="td-actions text-right">
                              <div className="d-flex" style={{float: 'right'}}>
                              <Button
                                className="p-1"
                                color="link"
                                title=""
                                onClick={() => handleEditContact(contact.email)} // Pass the contact ID here
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>

                              <Button
                                className="p-1"
                                color="link"
                                onClick={() => handleContactDelete(contact.id)}>
                                <i
                                  style={{ color: "red", fontSize: "1.25rem" }}
                                  className="tim-icons icon-trash-simple"
                                ></i>
                              </Button>
                              </div>
                            </td>
                          </tr>
              ))}
              </>
              : 
              <>
              {contacts.map((contact) => (
                          <tr key={contact.id}>
                            <td>{contact.name}</td>
                            <td>{contact.occupation}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phone}</td>
                            <td className="td-actions text-right">
                              <div className="d-flex" style={{float: 'right'}}>
                              <Button
                                className="p-1"
                                color="link"
                                title=""
                                onClick={() => handleEditContact(contact.email)} // Pass the contact ID here
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>

                              <Button
                                className="p-1"
                                color="link"
                                onClick={() => handleContactDelete(contact.id)}>
                                <i
                                  style={{ color: "red", fontSize: "1.25rem" }}
                                  className="tim-icons icon-trash-simple"
                                ></i>
                              </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
              </>}
                        
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </>
          ) : (
            <div align="center">
              <p>No contacts available.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
