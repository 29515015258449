// EditContactModal.js
import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Label, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

const EditContactModal = ({  contact, onUpdate, onCancel }) => {
  const [editedContact, setEditedContact] = useState(contact);

  useEffect(() => {
    // Update the edited contact when the contact prop changes
    setEditedContact(contact);
  }, [contact]);

  const handleUpdate = (e) => {
    e.preventDefault()
    // Perform validation if needed
    onUpdate(editedContact);
    onCancel()
  };


  const handleCancel = () => {
    onCancel();
    // Reset the edited contact state or perform any other necessary cleanup
  };


  return (
    <Card>
      <CardHeader toggle={handleCancel}>Edit Contact</CardHeader>
      <Form onSubmit={handleUpdate}>
      <CardBody>
        <div className='mt-1 mb-1'>
        <Label>Name</Label>
        <Input
          style={{ color: "black" }}
          type="text"
          placeholder="Name"
          value={editedContact.name}
          onChange={(e) => setEditedContact({ ...editedContact, name: e.target.value })}
        />
        </div>
        <div className='mt-1 mb-1'>
        <Label>Occupation</Label>
        <Input
          style={{ color: "black" }}
          type="text"
          placeholder="Occupation"
          value={editedContact.occupation}
          onChange={(e) => setEditedContact({ ...editedContact, occupation: e.target.value })}
        />
        </div>
        <div className='mt-1 mb-1'>
        <Label>Email</Label>
        <Input
          style={{ color: "black" }}
          type="email"
          placeholder="Email"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" // Email pattern validation
          value={editedContact.email}
          onChange={(e) => setEditedContact({ ...editedContact, email: e.target.value })}
        />
        </div>
        <div className='mt-1 mb-1'>
        <Label>Phone</Label>
        <Input
          style={{ color: "black" }}
          type="tel"
          placeholder="Phone # (e.g., (123) 456-7890)"
          pattern="\(\d{3}\) \d{3}-\d{4}" // Enforce format (###) ###-####
          maxLength="14" // Maximum characters for format (###) ###-####          
          value={editedContact.phone}
          onChange={(e) => setEditedContact({ ...editedContact, phone: e.target.value })}
        />
        <p style={{fontSize: '11px', color: 'grey'}}>* Required format: (123) 456-7890</p>
        </div>
      </CardBody>
      <CardFooter>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" type='submit'>
          Save
        </Button>
      </CardFooter>
      </Form>
    </Card>
  );
};

export default EditContactModal;
