import React from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import { Container } from 'reactstrap'

export default function ResourcesOtherServices() {
  return (
    <PageLayout 
    heading={"Guides and More"}
    background={
        "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      }
      content={
        <Container className="mt-5 mb-5">
            <div>
                <p>Learn about our other services. No matter what you need, we're here to help.</p>
            </div>
            <Container className='mt-5'>
            <h3 style={{textDecoration: 'underline'}}>Other Services</h3>
            <p className='text-black'>Whether you're navigating short sales, foreclosures, land acquisitions, commercial transactions, property preservation, or clean outs, our comprehensive range of real estate services has you covered. Our dedicated team provides expert guidance and support throughout every step of your real estate journey.</p>
            <br />

            <h3 className='text-black'>Short Sales</h3>
            <p className='text-black'>Navigate the complexities of short sales with our expert guidance, ensuring a smooth transaction process.</p>
            <br />

            <h3 className='text-black'>Foreclosure</h3>
            <p className='text-black'>Let us assist you in understanding the foreclosure process and explore your options for buying or selling distressed properties.</p>
            <br />

            <h3 className='text-black'>Land</h3>
            <p className='text-black'>Discover the perfect parcel of land for your development or investment needs with our comprehensive land listings and expertise.</p>
            <br />

            <h3 className='text-black'>Commercial</h3>
            <p className='text-black'>Unlock opportunities in the commercial real estate market with our tailored solutions for buying, selling, or leasing commercial properties.</p>
            <br />

            <h3 className='text-black'>Property Preservation</h3>
            <p className='text-black'>Ensure the integrity and maintenance of your property with our property preservation services, preserving its value for years to come.</p>
            <br />

            <h3 className='text-black'>Clean Outs</h3>
            <p className='text-black'>Streamline the process of clearing out properties with our efficient clean-out services, leaving your space clean and ready for its next chapter.</p>
            <br />

            <p className='text-black'>Whatever your real estate needs may be, you can count on our team to deliver exceptional service and results. Contact us today to learn more about how we can help you achieve your real estate goals.</p>
            </Container>
        </Container>
      }
    />
  )
}
