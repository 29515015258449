import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Spinner,
  Container,
  Alert,
  Row,
  Col,
} from "reactstrap";
import { UserAuth } from "contexts/AuthContext";

import { Link, useNavigate } from "react-router-dom";
import "./signin.css";
import HomeNav from "components/Navbars/HomeNav";

export default function SignIn() {
  const { currentUser, signin } = UserAuth();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (currentUser) {
      setError("You're already logged in.");
      return;
    }

    // register try
    try {
      await signin(email, password);
      navigate("/admin");
    } catch (error) {
      setError("Failed to log in. Please try again.");
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <>
      <HomeNav />
      <Container
        id="signin-container"
        fluid
        className="align-items-center justify-content-center d-flex p-0"
      >
        <Container id="signin-overlay" fluid className="p-0 h-100"></Container>
        <div className="w-100 d-flex align-items-center justify-content-center p-2">
        <Card id="signin-card">
          {error ? <Alert color="danger">{error}</Alert> : <></>}
          {currentUser ? <Alert color="danger">You're already logged in.</Alert> : <></>}
          <CardBody>
            <div>
              <h4 style={{ color: "white" }}>Admin Sign In</h4>
            </div>
            <form onSubmit={login} id="sigin-form">
              <FormGroup>
                <Label for="Email" style={{ color: "white" }}>
                  Email address
                </Label>
                <Input
                  disabled={currentUser}
                  style={{ color: "white" }}
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="Password" style={{ color: "white" }}>
                  Password
                </Label>
                <Input
                  disabled={currentUser}
                  style={{ color: "white" }}
                  type="password"
                  name="password"
                  id="examplePassword"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormGroup>
              {loading ? (
                <>
                  <Spinner color="primary">Loading...</Spinner>
                </>
              ) : (
                <>
                {currentUser ? 
                  <>
                  <Link to='/admin/dashboard'>
                  <Button color="primary" type="submit">
                    Go To Dashboard
                  </Button>
                  </Link>
                  </> 
                  : 
                  <>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  </>}
                  
                </>
              )}
            </form>
          </CardBody>
        </Card>
        </div>
      </Container>
    </>
  );
}
