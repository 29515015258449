import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.js'

import './index.css'
import { Link } from 'react-router-dom';

function PropertyList() {
  // firebase state
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  // firebase data fetch for properties
    // useEffect function to fetch properties on load or component mount
    useEffect(() => {
      fetchProperties();
    }, []);
  
    // Function to fetch properties from firebase
    const fetchProperties = async () => {
      setLoading(true);
      try {
        const propertiesCollection = await collection(db, 'properties');
        const querySnapshot = await getDocs(propertiesCollection);
  
        const propertyData = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Capture the document ID
          ...doc.data(), // Capture the document data
        }));

        propertyData.sort((a, b) => b.price - a.price);


        setProperties(propertyData.slice(0, 6)); // Limiting to 6 properties
        console.log(propertyData);
      } catch (error) {
        console.error('Error fetching properties: ', error);
      } finally {
        setLoading(false);
      }
    };


  if(loading){
    return(
      <p>
        Loading...
      </p>
    )
  }

  if (properties.length === 0 && !loading) {
    return (
      <Container fluid className='fallback'>
        <div id='fallback-div'>
          <div id='fallback-div-overlay'>
            <h2 id='fallback-text'>Established and Reliable Proficiency</h2>
            <Link to="/about">
              <Button>Learn More</Button>
            </Link>
          </div>
          </div>
      </Container>
    )
  }

  return (
    <>
    <h2>Featured Properties</h2>
    <Row className='p-0'>
      {properties.map((property) => {
        return(
          <Col xs='12' md='6' lg='4'>
            <Link to={`/properties/${property.address}`}>
            <Card style={{maxWidth: '35rem'}} className='mx-auto'>
              <CardHeader>
                <h3 className='mb-1'>{property.address}</h3>
                <h5>{property.city} {property.state}</h5>
                </CardHeader>
                {property.images ? 
                <>
                  <img src={property.images[0]} style={{width: '100%', height: '300px', objectFit: 'cover'}} />
                </> 
                : null}
              <CardBody>
                <div>
                  <p>{property.description.length > 75 ? `${property.description.substring(0, 75)}...` : property.description}</p>
                </div>
              </CardBody>
              <CardFooter>
                  <p>${property.price}</p>
                  <p>Beds: {property.bedNumber} | Baths: {property.bathNumber} | SQFT: {property.sqft}</p>
              </CardFooter>
            </Card>
            </Link>
          </Col>
        )
      })}
    </Row>
    {properties.length > 6 ? 
    <>
      <div>
        <Link to='/properties'><p>View More</p></Link>
      </div>
    </> 
    : null}
    </>
  );
}

export default PropertyList;