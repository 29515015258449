import { useContext, createContext, useState, useEffect } from "react";
import { db } from "../firebase.js"
import { getDoc, doc, updateDoc } from "firebase/firestore"; // Import the getDoc function


const TemplateContext = createContext(null)

export function TemplateContextProvider({ children }) {
    const [templateState, setTemplateState] = useState({
        heroImage: "",
        heroText: "Real Estate Template",
        heroSubtext: "Your professional real estate expert",

        aboutImage: "",
        aboutTextHeading: "About Section",
        aboutTextSubheading: "lorem ipsum...",
        aboutTextParagraph: "lorem ipsum...",
        aboutTextQuote: "lorem ipsum...",

        services: [
            {
                title: "Buying",
                description: "lorem ipsum...",
            },
            {
                title: "Selling",
                description: "lorem ipsum...",
            },
            {
                title: "Rental Services",
                description: "lorem ipsum...",
            },
        ],

        servicesImage: "",


        signinBannerImage: "",

      });

      useEffect(() => {
        async function fetchData() {
          try {
            const docRef = doc(db, "settings", "ZwW2RpEfvA0owg9LuG69");
            const docSnap = await getDoc(docRef);
      
            if (docSnap.exists()) {
              setTemplateState(docSnap.data());
            } else {
              console.log("No such document!");
              // If the document doesn't exist, you can choose to set the default state here
              // setTemplateState(defaultState); // Set your default state here
            }
          } catch (error) {
            console.error("Error fetching document:", error);
          }
        }
      
        fetchData();
      }, []);
      
    
      const updateTemplate = async (newState) => {
        setTemplateState({
          ...templateState,
          ...newState,
        });
    
        // Update the data in Firebase using updateDoc
        const docRef = doc(db, "settings", "ZwW2RpEfvA0owg9LuG69");
        await updateDoc(docRef, newState);
      };
      

    return(
        <TemplateContext.Provider value={{
            updateTemplate,
            templateState,
        }}>{children}</TemplateContext.Provider>
    )
}

export const TemplateContextData = () => {
    return useContext(TemplateContext)
}

