import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import { useAnalytics } from "contexts/AnalyticsContext.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// custom components
import Tasks from "components/Tasks/Tasks";
import Inspiration from "components/Inspiration/Inspiration";

import MyAnalytics from "components/MyAnalytics/MyAnalytics";
import CommissionCalculator from "components/CommissionCalculator/CommissionCalculator";
import MortgageCalculator from "components/MortgageCalculator/MortgageCalculator";

function Dashboard(props) {
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Inspiration />
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <MyAnalytics />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Tasks />
          </Col>
          </Row>
        <Row>
          <Col xs='12' md='6'>
            <CommissionCalculator />
          </Col>
          <Col xs='12' md='6'>
            <MortgageCalculator />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
