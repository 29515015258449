import React, {useEffect, useState} from 'react'
import { collection, getDocs, addDoc, doc, deleteDoc, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase.js'
import { Row, Col, CardBody, Card, CardHeader, Button, Form, FormGroup, Label, Input, CardFooter } from 'reactstrap';

export default function Reviews() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [newReview, setNewReview] = useState({ author: '', comment: '', rating: null });

  useEffect(() => {
    fetchData();
  }, []);

  // function for fetching data from firebase
  const fetchData = async () => {
    setLoading(true);

    try {
      const reviewsCollectionRef = collection(db, "reviews");
      const q = query(reviewsCollectionRef, orderBy("date", "desc")); // Sort by date in descending order
      const querySnapshot = await getDocs(q);
      
      const reviewsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(reviewsData);
      setData(reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  // rendering stars for reviews
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="tim-icons icon-shape-star"></span>);
    }
    return stars;
  };

  // functions for adding a new review
// handles form change
const handleFormChange = e => {
  const { name, value } = e.target;

  // Ensure the rating value does not exceed 5
  const rating = name === 'rating' ? Math.min(parseInt(value), 5) : value;
  
  setNewReview(prevState => ({
    ...prevState,
    [name]: rating,
  }));
};


// handles adding a review functionality
const handleAddReview = async (e) => {
  e.preventDefault()
  try {

    if(!newReview.author, !newReview.comment, !newReview.rating){
      alert('Please enter all fields')
      return
    }

    if(newReview.rating < 0){
      alert('You can not use a negative review number.')
      return
    }
    // Validate if the comment is not empty and does not exceed 200 characters
    if (newReview.comment.trim().length === 0) {
      alert('Please provide a comment for the review.');
      return;
    }
    if (newReview.comment.length > 200) {
      alert('Review comment should not exceed 200 characters.');
      return;
    }

    const currentDate = new Date();
    const reviewWithDate = { ...newReview, date: currentDate };

    const docRef = await addDoc(collection(db, 'reviews'), reviewWithDate);
    console.log('Document written with ID: ', docRef.id);
    setNewReview({ author: '', comment: '', rating: null });
    setShowForm(false);
    fetchData(); // Refresh reviews after adding a new one
  } catch (error) {
    console.error('Error adding review:', error);
  }
};

  
    // handles deleting a review
    const handleDeleteReview = async (reviewId) => {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this review?"
      );

      if(confirmDelete){
        try {
          await deleteDoc(doc(db, 'reviews', reviewId));
          console.log('Review deleted successfully');
          fetchData(); // Refresh reviews after deleting
        } catch (error) {
          console.error('Error deleting review:', error);
        }
      }
    };

  return (
    <div className='content'>
      {loading && !data ? (
        <>
          <p style={{color: 'black'}}>Loading...</p>
        </>
      ) : null}
    {showForm ?  
        <>
          <Form onSubmit={handleAddReview}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label for="author">Author</Label>
                  <Input type="text" name="author" id="author" required value={newReview.author} onChange={handleFormChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label for="comment">Comment</Label>
                  <Input type="textarea" name="comment" id="comment" maxLength={200} required value={newReview.comment} onChange={handleFormChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='1'>
                <FormGroup>
                  <Label for="rating">Rating</Label>
                  <Input type="number" name="rating" id="rating" required value={newReview.rating} onChange={handleFormChange} />
                </FormGroup>
              </Col>
            </Row>
            <Button color="secondary" onClick={() => setShowForm(!showForm)}>
              Cancel
            </Button>
            <Button color="primary" type='submit'>
              Submit
            </Button>
          </Form>
        </> 
        : 
        <>
        {!loading && data.length > 0 ? (
        <>
        <p onClick={() => setShowForm(!showForm)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Add A Review</p>
          <Card>
            <CardHeader>
              <h6>My Reviews</h6>
            </CardHeader>
            <Row>
              {data.map((review) => {
                return (
                  <Col xs="12" md="6" lg="4">
                    <div
                      style={{
                        maxWidth: "23rem",
                        width: "100%",
                        height: "400px",
                        overflowY: "hidden",
                      }}
                    >
                      <CardBody>
                        <h3 className="text-black">{review.author}</h3>
                        <div>
                          <p className="text-black">{review.comment}</p>
                          <p className="text-black" style={{fontSize: '12px'}}>Date: {new Date(review.date.seconds * 1000).toLocaleDateString()}</p>
                          <div>
                            <span style={{ color: "gold" }}>
                              {renderStars(review.rating)}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                      <CardFooter>
                          <i
                            className='tim-icons icon-trash-simple m-2'
                            style={{ fontSize: '1.25rem', color: 'red', cursor: 'pointer' }}
                            onClick={() => handleDeleteReview(review.id)}
                          ></i>
                      </CardFooter>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Card>
          </>
      ) :         
      <>
      <p onClick={() => setShowForm(!showForm)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Add A Review</p>
      <div align="center">
        <p>No reviews available.</p>
      </div>
      </>
    }
        </>
      }

    </div>
  )
}
