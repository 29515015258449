import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    CardHeader,
    Card,
    CardTitle,
    CardImg,
    FormGroup,
    Label,
    Form,
    Input,
    Alert
} from 'reactstrap';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';

export default function Lenders() {
    const [lenders, setLenders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLender, setSelectedLender] = useState(null);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        institution: '',
        nmls: '',
        link: '',
        image: '',
    });

    const [showForm, setShowForm] = useState(false);

    // Function to fetch lenders from firebase
    const fetchLenders = async () => {
        setLoading(true);
        try {
            const lendersCollection = await collection(db, 'lenders');
            const querySnapshot = await getDocs(lendersCollection);

            const lendersData = querySnapshot.docs.map((doc) => ({
                id: doc.id, // Capture the document ID
                ...doc.data(), // Capture the document data
            }));
            setLenders(lendersData);
            console.log(lendersData);
        } catch (error) {
            console.error('Error fetching lenders: ', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle form submission for adding or updating a lender
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!formData.name || !formData.institution || !formData.image || !formData.nmls || !formData.link) {
          setMessage('All fields required.');
          return;
      }
  
      setLoading(true);
  
      try {
          let lenderData;
          const file = formData.image;
  
          // Upload image to Firebase Storage
          const fileName = `${file.name}_${Math.random().toString(36).substring(7)}`;
          const storageRef = ref(storage, `lenders/${fileName}`);
          await uploadBytes(storageRef, file);
          let downloadURL = await getDownloadURL(storageRef);
  
          if (selectedLender) {

            // Check if the user has selected a new image for the lender
            if (formData.image && typeof formData.image === 'object') {
              // Upload the new image to Firebase Storage
              const newFileName = `${file.name}_${Math.random().toString(36).substring(7)}`;
              const newStorageRef = ref(storage, `lenders/${newFileName}`);
              await uploadBytes(newStorageRef, file);
              downloadURL = await getDownloadURL(newStorageRef);
            } else {
              // If no new image is selected, retain the existing image path
              downloadURL = formData.image;
            }
              // Update existing lender
              lenderData = {
                  name: formData.name,
                  institution: formData.institution,
                  nmls: formData.nmls,
                  link: formData.link,
                  image: downloadURL,
              };
  
              // Construct Firestore document reference for the selected lender
              const lenderRef = doc(db, 'lenders', selectedLender);
              await updateDoc(lenderRef, lenderData); // Update the document with new data
              setMessage('Lender successfully updated');
          } else {
              // Add new lender
              lenderData = {
                  name: formData.name,
                  institution: formData.institution,
                  nmls: formData.nmls,
                  link: formData.link,
                  image: downloadURL,
              };
  
              // Add new lender document to Firestore collection
              await addDoc(collection(db, 'lenders'), lenderData);
              setMessage('Lender successfully added');
          }
  
          // Refresh lenders data
          fetchLenders();
      } catch (error) {
          console.error('Error submitting lender: ', error);
          setMessage('There has been an error...please try again.');
      } finally {
          setLoading(false);
          setFormData({
              name: '',
              institution: '',
              nmls: '',
              link: '',
              image: '',
          });
          setShowForm(false);
          handleMessageReset();
      }
  };
  

    // Function to handle rendering image preview
    const renderImagePreview = () => {
      if (typeof formData.image === 'string') {
          // If formData.image is already a URL, no need for conversion
          return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                      src={formData.image}
                      style={{ width: '150px', marginRight: '10px', height: '150px' }}
                      className='mt-1 mb-1'
                      alt='Lender Preview'
                  />
              </div>
          );
      } else if (formData.image instanceof File) {
          // If formData.image is a File object, convert it to URL
          return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                      src={URL.createObjectURL(formData.image)}
                      style={{ width: '150px', marginRight: '10px', height: '150px' }}
                      className='mt-1 mb-1'
                      alt='Lender Preview'
                  />
              </div>
          );
      } else {
          return null; // Return null if formData.image is neither a string nor a File
      }
  };

    // Function to reset message after delay
    const handleMessageReset = () => {
        setTimeout(() => {
            setMessage('');
        }, 2500);
    };

    // Function to handle editing selection
    const handleEditSelection = async (id) => {
        setShowForm(true);
        setSelectedLender(id);
        const selectedLenderData = lenders.find((lender) => lender.id === id);
        setFormData({
            name: selectedLenderData.name,
            institution: selectedLenderData.institution,
            nmls: selectedLenderData.nmls,
            link: selectedLenderData.link,
            image: selectedLenderData.image,
        });
    };

    // Function to handle deleting a lender
const handleDeleteLender = async (id) => {
  setLoading(true);
  
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this lender?"
  );

  if(!confirmDelete){
    return
  }
  
  try {
      // Construct Firestore document reference for the selected lender
      const lenderRef = doc(db, 'lenders', id);
      await deleteDoc(lenderRef); // Delete the document
      setMessage('Lender successfully deleted');
      // Refresh lenders data
      fetchLenders();
  } catch (error) {
      console.error('Error deleting lender: ', error);
      setMessage('There has been an error...please try again.');
  } finally {
      setLoading(false);
      handleMessageReset();
  }
};

// JSX for the delete icon button
<i
  className='tim-icons icon-trash-simple m-2'
  style={{ fontSize: '1.25rem', color: 'red', cursor: 'pointer' }}
  onClick={() => handleDeleteLender(lender.id)}
></i>


    // Function to toggle form visibility and reset form data
    const toggle = () => {
        setShowForm(!showForm);
        setFormData({
            name: '',
            institution: '',
            nmls: '',
            link: '',
            image: '',
        });
    };

    // Fetch lenders data on component mount
    useEffect(() => {
        fetchLenders();
    }, []);

    return (
        <div className='content'>
            <Row>
                <Col xs='12'>
                    <p
                        onClick={() => setShowForm(true)}
                        style={{ textDecoration: 'underline', cursor: 'pointer', width: '110px' }}
                    >
                        Add A Lender
                    </p>
                    {message && <Alert>{message}</Alert>}
                    {!showForm ? (
                        <>
                            {lenders && lenders.length > 0 ? (
                                <>
                                    <Card>
                                        <CardHeader>
                                            <h6>My Lenders</h6>
                                        </CardHeader>
                                        <CardBody>
                                            {lenders.map((lender, index) => (
                                                <Card key={index}>
                                                    <CardHeader>
                                                        <h3 className='p-0 mb-0' style={{ textDecoration: 'underline' }}>
                                                            {lender.name}
                                                        </h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <CardImg
                                                            src={lender.image}
                                                            style={{ height: '300px', width: 'auto', objectFit: 'cover' }}
                                                        />
                                                        <p>
                                                            <span style={{ fontWeight: 'bold' }}>Institution:</span> {lender.institution}
                                                        </p>
                                                        <p>
                                                            <span style={{ fontWeight: 'bold' }}>NMLS #:</span> {lender.nmls}
                                                        </p>
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 'bold' }}>Website Link: </span>
                                                                <a href={lender.link} target='_blank' style={{ color: 'black', textDecoration: 'underline' }}>
                                                                    View Here
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <Button onClick={() => handleEditSelection(lender.id)}>Edit</Button>
                                                        <i
                                                            className='tim-icons icon-trash-simple m-2'
                                                            style={{ fontSize: '1.25rem', color: 'red', cursor: 'pointer' }}
                                                            onClick={() => handleDeleteLender(lender.id)}
                                                        ></i>
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </>
                            ) : (
                                <div align='center'>
                                    {lenders.length === 0 && <p style={{ color: 'black' }}>No lenders currently.</p>}
                                </div>
                            )}
                        </>
                    ) : (
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label>Lender Name</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='lenderNameInput'
                                            name='name'
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Lender Institution</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='lenderInstitutionInput'
                                            name='institution'
                                            value={formData.institution}
                                            onChange={(e) => setFormData({ ...formData, institution: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Lender NMLS</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='lenderNMLSInput'
                                            name='nmls'
                                            value={formData.nmls}
                                            onChange={(e) => setFormData({ ...formData, nmls: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Lender Website Link</Label>
                                        <Input
                                            required
                                            type='text'
                                            id='lenderLinkInput'
                                            name='link'
                                            value={formData.link}
                                            onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Lender Image</Label>
                                        <Input
                                            type='file'
                                            name='image'
                                            id='lenderImageInput'
                                            onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                                            accept='image/*'
                                        />
                                        <div style={{ marginTop: '10px' }}>{formData.image && renderImagePreview()}</div>
                                    </FormGroup>
                                    <Button onClick={toggle} color='secondary' variant='secondary'>
                                        Cancel
                                    </Button>
                                    <Button type='submit' color='primary' disabled={loading}>
                                        Submit
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </div>
    );
}