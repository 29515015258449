import React, {useState} from 'react'
import PageLayout from 'components/PageLayout/PageLayout';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase.js';

export default function ReviewRequest() {
    const [newReview, setNewReview] = useState({ author: '', comment: '', rating: 0 });
    const [loading, setLoading] = useState(false)

    // functions for adding a new review
    // handles form change
    const handleFormChange = e => {
        const { name, value } = e.target;
        setNewReview(prevState => ({
        ...prevState,
        [name]: value,
        }));
    };

    // handles adding a review functionality
    const handleAddReview = async () => {
        setLoading(true);
        try {
        const currentDate = new Date();
        const reviewWithDate = { ...newReview, date: currentDate };
    
        const docRef = await addDoc(collection(db, 'reviews'), reviewWithDate);
        console.log('Document written with ID: ', docRef.id);
        setNewReview({ author: '', comment: '', rating: 0 });
        } catch (error) {
        console.error('Error adding review:', error);
        } finally{
            setLoading(false)
        }
    };

    return (
        <PageLayout 
            heading='Review Request' 
            background={'https://images.pexels.com/photos/18512803/pexels-photo-18512803/free-photo-of-the-word-feedback-is-spelled-out-with-scrabble-tiles.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            content={
                    <>
                    <p>We'd love if you could leave a review of our services.</p>
                    <div style={{maxWidth: '30rem'}}>
                    <Form>
                        <FormGroup>
                        <Label for="author">Author</Label>
                        <Input type="text" name="author" id="author" required value={newReview.author} onChange={handleFormChange} />
                        </FormGroup>
                        <FormGroup>
                        <Label for="comment">Comment</Label>
                        <Input type="textarea" name="comment" id="comment" required value={newReview.comment} onChange={handleFormChange} />
                        </FormGroup>
                        <FormGroup>
                        <Label for="rating">Rating</Label>
                        <Input type="number" name="rating" id="rating" required value={newReview.rating} onChange={handleFormChange} />
                        </FormGroup>
                        <Button color="primary" onClick={handleAddReview} disabled={loading}>
                        Submit
                        </Button>
                    </Form>
                    </div>
                    </>
                } 
        />
      )
}
