import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { Form, FormGroup, Label, Input, Button, Alert, Row, Col } from 'reactstrap';

const PropertyForm = ({ onPropertyAdded, selectedProperty, onFormSubmit, resetSelectedProperty }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState(null);
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState('');
  const [bedNumber, setBedNumber] = useState('');
  const [bathNumber, setBathNumber] = useState('');
  const [sqft, setSQFT] = useState('');
  const [newImages, setNewImages] = useState([]); // State to track new images to be added
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [message, setMessage] = useState('')
  
// Define state to keep track of deleted image URLs
const [deletedImageUrls, setDeletedImageUrls] = useState([]);
  
// UseEffect function to reset all messages after 3.5 sec
  useEffect(() => {
    setTimeout(() => {
      setMessage('')
    }, 3500)
  }, [message])

  useEffect(() => {
    // Pre-fill form fields if a property is selected for editing
    if (selectedProperty) {
      console.log(selectedProperty)
      setAddress(selectedProperty.address || '');
      setCity(selectedProperty.city || '');
      setState(selectedProperty.state || '');
      setZipcode(selectedProperty.zipcode || '');
      setPrice(selectedProperty.price || 0);
      setBathNumber(selectedProperty.bathNumber || 0);
      setBedNumber(selectedProperty.bedNumber || 0);
      setSQFT(selectedProperty.sqft || 0);
      setDescription(selectedProperty.description || '');
      setImageUrls(selectedProperty.images || []);
    }
  }, [selectedProperty]);
  
  // Function to handle image uploads
// Function to handle image uploads
const handleImageUpload = async (e) => {
  const files = e.target.files;

  if (files && files.length > 0) {
    const urls = [];
    const imagePromises = [];

    // Append newly uploaded image URLs to the existing imageUrls state
    const updatedImageUrls = [...imageUrls];
    // Store the newly uploaded image files
    const updatedImages = [...images];

    Array.from(files).forEach(file => {
      imagePromises.push(new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          urls.push(e.target.result);
          updatedImageUrls.push(e.target.result); // Append newly uploaded image URL
          updatedImages.push(file); // Store the newly uploaded image file
          resolve();
        };
        reader.readAsDataURL(file);
      }));
    });

    await Promise.all(imagePromises);

    setImageUrls(updatedImageUrls);
    setImages(updatedImages);  // Update the images state with the new images

    // Update the newImages state with the newly uploaded images
    setNewImages([...newImages, ...Array.from(files)]);
  }
};

  
  // Function to handle deleting an image
// Function to handle deleting an image
const handleDeleteImage = (index) => {
  // Create a copy of the current arrays
  const updatedImageUrls = [...imageUrls];
  const updatedImages = [...images];
  
  // Remove the previewed image URL and image file from the copies
  updatedImageUrls.splice(index, 1);
  updatedImages.splice(index, 1);
  
  // Update the state with the modified arrays
  setImageUrls(updatedImageUrls);
  setImages(updatedImages);

  // Update the deletedImageUrls state with the URL of the deleted image
  setDeletedImageUrls(prevDeletedImageUrls => {
    const newDeletedImageUrls = [...prevDeletedImageUrls];
    newDeletedImageUrls.push(imageUrls[index]);
    return newDeletedImageUrls;
  });
};

  
  

  // Function to handle rendering images before uploads
  const renderImagePreviews = () => {
    return imageUrls.map((url, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        <img src={url} alt={`Image ${index}`} style={{ width: '150px', marginRight: '10px', height: '150px' }} className='mt-1 mb-1' />
        <i 
          className='tim-icons icon-trash-simple' 
          onClick={() => handleDeleteImage(index)} 
          style={{ cursor: 'pointer', fontSize: '1.25rem', color: 'red' }} > </i>
      </div>
    ));
  };

  // Function to handle updating a properties' information -- CURRENTLY UNFINISHED!!!
// Function to handle updating a property's information
const handleUpdateProperty = async (propertyId, updatedData) => {
  try {
    const propertyDoc = doc(db, 'properties', propertyId);
    const propertySnapshot = await getDoc(propertyDoc);
    const existingData = propertySnapshot.data();

    // Get the existing images if available, otherwise default to an empty array
    const existingImages = existingData.images || [];

    // Identify images that need to be deleted
    const imagesToDelete = deletedImageUrls.filter(imageUrl => existingImages.includes(imageUrl));

    // Remove the deleted images from Firebase Storage
    for (const imageUrl of imagesToDelete) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    }

    // Filter out deleted images from the updated data
    updatedData.images = existingImages.filter(imageUrl => !imagesToDelete.includes(imageUrl));

    // Upload new images to storage and get their download URLs
    const newImageReferences = await uploadNewImages(newImages); // Use newImages state here

    // Concatenate the existing images with the new image URLs
    updatedData.images = updatedData.images.concat(newImageReferences);

    // Update the property data in Firestore
    await updateDoc(propertyDoc, updatedData);
    console.log('Property updated successfully.');
  } catch (error) {
    console.error('Error updating property: ', error);
  }
};


// Function to upload new images to storage and return their download URLs
const uploadNewImages = async (newImages) => {
  const newImageReferences = [];
  for (const file of newImages) {
    // Generate a random string to append to the file name
    const randomString = Math.random().toString(36).substring(7);
    const fileName = `${file.name}_${randomString}`;

    const storageRef = ref(storage, `properties/${fileName}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    newImageReferences.push(downloadURL);
  }
  return newImageReferences;
};


  // Submits the property and it's infomation/images to firebase
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checks for required information
    // Secondary validation check to 'required' boolean attribute in form component
    if(!address, !city, !state, !zipcode, !bedNumber, !bathNumber, !sqft, !description){
      setMessage('All fields required.')
      return
    }
    
    // Sets loading 
    setLoading(true);
  
    // Try catch to submit property information and images to firebase
    
    try {
      const imageReferences = [];
  
      for (const file of images) {
        const storageRef = ref(storage, `properties/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        imageReferences.push(downloadURL);
      }

      if (selectedProperty) {
        await handleUpdateProperty(selectedProperty.id, {
          address,
          city,
          state,
          zipcode,
          price,
          description,
          bedNumber,
          bathNumber,
          sqft,
          images: imageReferences,
        });
        resetSelectedProperty()
      } else {
        const propertyData = {
          address,
          city,
          state,
          zipcode,
          price,
          description,
          bedNumber,
          bathNumber,
          sqft,
          images: imageReferences,
        };

        await addProperty(propertyData);
      }
        
      setMessage("Property successfully " + (selectedProperty ? "updated" : "added"));

       // Call the callback function to refresh properties
       onPropertyAdded();
      
    } catch (error) {
      console.error('Error submitting property: ', error);
      setMessage('There has been an error...please try again.')
    } finally {
      setLoading(false);
      // Reset form state
      setAddress('');
      setCity('');
      setState('');
      setAddress('');
      setPrice(null);
      setZipcode(null);
      setBathNumber(0);
      setBedNumber(0);
      setSQFT(0);
      setDescription('');
      setImageUrls([]);
    }
  };

  const statesList = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];
    
const handleStateChange = (e) => {
  setState(e.target.value);
};
  
  
// Function to add new properties
const addProperty = async (data) => {
  try {
    // Add the current date as the dateAdded field
    data.dateAdded = new Date();
    
    const propertyRef = await addDoc(collection(db, 'properties'), data);
    console.log('Property added with ID: ', propertyRef.id);
  } catch (error) {
    console.error('Error adding property: ', error);
  }
};


  return (
    <div>
      {selectedProperty ? <h4>Edit A Property</h4> : <h4>Add A Property</h4>}
      <Form onSubmit={handleSubmit}>
        {message && (
          <Alert>
            {message}
         </Alert>
         )}
         <Row>
          <Col lg='5'>
          <FormGroup>
          <Label for="address">Address</Label>
          <Input
            type="text"
            name="address"
            id="address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
          </FormGroup>
          </Col>
         </Row>
         
         <Row>
         <Col xs='5' md='4' lg='2'>
          <FormGroup>
          <Label for="city">City</Label>
          <Input
            type="text"
            name="city"
            id="city"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          </FormGroup>
          </Col>
          <Col xs='3' md='2' lg='1'>
          <FormGroup>
          <Label for="state">State</Label>
          <Input
            type="select"
            name="state"
            id="state"
            required
            value={state}
            onChange={handleStateChange}
          >
            <option value="">Select State</option>
            {statesList.map(state => (
              <option key={state.value} value={state.value}>{state.label}</option>
            ))}
          </Input>
        </FormGroup>
          </Col>
          <Col xs='3' md='2' lg='1'>
          <FormGroup>
          <Label for="zipcode">Zipcode</Label>
          <Input
            type="text"
            name="zipcode"
            id="zipcode"
            required
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </FormGroup>
          </Col>
         </Row>

        <Row>
          <Col xs='5' md='4' lg='2'>
          <FormGroup>
          <Label for="price">Price</Label>
          <div className='d-flex justify-content-center align-items-center'>
            <p className='mr-3'>$</p>
            <Input
            required
            type="number"
            name="price"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          </div>
        </FormGroup>
          </Col>
          <Col xs='3' md='2' lg='1'>
          <FormGroup>
          <Label for="bedNumber"># of Bedrooms</Label>
          <div className='d-flex justify-content-center align-items-center'>
            <Input
            required
            type="number"
            name="bedNumber"
            id="bedNumber"
            value={bedNumber}
            onChange={(e) => setBedNumber(e.target.value)}
          />
          </div>
        </FormGroup>
          </Col>
          <Col xs='3' md='2' lg='1'>
          <FormGroup>
          <Label for="bathNumber"># of Bathrooms</Label>
          <div className='d-flex justify-content-center align-items-center'>
            <Input
            required
            type="number"
            name="bathNumber"
            id="bathNumber"
            value={bathNumber}
            onChange={(e) => setBathNumber(e.target.value)}
          />
          </div>
        </FormGroup>
          </Col>
          <Col xs='3' md='2' lg='1'>
          <FormGroup>
          <Label for="sqft">SQFT.</Label>
          <div className='d-flex justify-content-center align-items-center'>
            <Input
            required
            type="number"
            name="sqft"
            id="sqft"
            value={sqft}
            onChange={(e) => setSQFT(e.target.value)}
          />
          </div>
        </FormGroup>
          </Col>
        </Row>
        
        <Row>
        <Col lg='5'>

        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        </Col>
        </Row>

        <FormGroup>
          <Label for="images">Add Image(s)</Label>
          <Input
            type="file"
            name="images"
            id="images"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </FormGroup>
        <div style={{ marginTop: '10px' }}>
            {imageUrls.length > 0 && renderImagePreviews()}
          </div>
        <Button type="submit" color="primary" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    </div>
  );
};

export default PropertyForm;

