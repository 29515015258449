import React, {useState, useEffect} from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import { CardBody } from 'reactstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.js'
import {Row, Col} from 'reactstrap';

export default function Reviews() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        setLoading(true)

        try {
          const reviewsCollectionRef = collection(db, "reviews");
          const querySnapshot = await getDocs(reviewsCollectionRef);
    
          const reviewsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
    // Filter out reviews with ratings of 3 or less
    const filteredReviews = reviewsData.filter(review => review.rating > 3);

    // Sort reviews by date (assuming there's a 'date' field in each review)
    filteredReviews.sort((a, b) => new Date(b.date) - new Date(a.date));

          console.log(filteredReviews)
          setData(filteredReviews);
        } catch (error) {
          console.error("Error fetching reviews:", error);
        } finally {
            setLoading(false)
        }
      };

      const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<span key={i} className="tim-icons icon-shape-star"></span>);
        }
        return stars;
    };

    return (
        <PageLayout 
            heading='Client Reviews' 
            background={'https://images.unsplash.com/photo-1523217582562-09d0def993a6?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
            content={
                    <>
                    {loading && !data ? <><p className='text-black'>Loading...</p></> : null}
                    {!loading && !data ? <><p className='text-black'>Currently, we have no reviews to display.</p></> : null}
                    {!loading && data.length > 0 ? 
                    <>
                    <p className='text-black'>Reviews from our various clients whom we've been blessed to be able to assist throughout their real estate journey.</p>
                    <br />
                    <Row>
                    {data.map((review) => {
                        return(
                            <Col xs='12' md='6' lg='4'>
                                <div style={{maxWidth: '23rem', width: '100%', height: '400px', overflowY: 'hidden'}}>
                                    <CardBody>
                                        <h3 className='text-black'>{review.author}</h3>
                                        <div>
                                            <p className='text-black'>{review.comment}</p>
                                            <div>
                                                <span style={{color: 'gold'}}>{renderStars(review.rating)}</span>
                                            </div>
                                            <p className="text-black" style={{fontSize: '12px'}}>Date: {new Date(review.date.seconds * 1000).toLocaleDateString()}</p>
                                        </div>
                                    </CardBody>
                                </div>
                            </Col>
                        )
                    })}
                    </Row>
                    </>
                    : <p className='text-black'>Currently, we have no reviews to display.</p>}
                    </>
            } 
        />
      )
}
