import React from 'react'
import './hero.css'
import { Container } from 'reactstrap'

export default function Hero() {

  return (
    <Container 
        fluid
        id='hero'
        className='d-flex align-items-center'
      >
        <div id='hero-overlay' className='d-flex align-items-center'>
            <Container align='center'>
                <h1 className='bold text-white' id='hero-text'>CB Belle Properties</h1>
                <h2 className='text-white' id='hero-subtext'>Providing tailored solutions for all home owners</h2>
            </Container>
        </div>
    </Container>
  )
}
