import React from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function Rentals() {
  return (
    <PageLayout 
        heading={"Find A Rental Home"}
        background={
            "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
        content={
            <>
                <div>
                    <p>
                        Are you looking for a rental property to move into? Our brokerage Keller Williams has access to thousands of rentals across the US.
                        <br />
                        <br />
                        Click the link below to view our rental properties.
                    </p>
                    <div>
                        <Link to='https://www.kwshreveportrentals.com/' target='_blank'>
                            <Button>View Rentals</Button>
                        </Link>
                    </div>
                </div>   
            </>
        }
    />
  )
}
