import React, { useState, useEffect } from 'react'
import { CardBody, CardFooter, CardImg } from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.js";
import { Row, Col } from "reactstrap";
import './index.css'

export default function Downloadables() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const resourcesCollectionRef = collection(db, "resources");
      const querySnapshot = await getDocs(resourcesCollectionRef);

      const resourcesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(resourcesData);
      setData(resourcesData);
    } catch (error) {
      console.error("Error fetching lenders list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
        {loading && !data ? (
            <>
            <p className="text-black">Loading...</p>
            </>
        ) : null}
        {!loading && !data ? <></> : null}
        {!loading && data.length > 0 ? 
            <>
            <h3 style={{textDecoration: 'underline'}}>Our Free Reources</h3>
            <Row>
            {data.map((resource) => {
              return (
                <Col xs="12" md="6" lg="4">
                  <div
                    style={{
                      maxWidth: "23rem",
                      width: "100%",
                      height: "400px",
                      overflowY: "hidden",
                    }}
                  >
                    <CardBody>
                      <h3 className="text-black">{resource.name}</h3>
                      <div>
                        <p className="text-black">{resource.description}</p>
                      </div>
                    </CardBody>
                    <CardFooter>
                        <a href={resource.link} target='_blank'>
                            <p>View Now</p>
                        </a>
                    </CardFooter>
                  </div>
                </Col>
              );
            })}
          </Row>
            </> 
            : null}
      </>
  )
}
