import React, { useState, useEffect } from "react";
import { CardBody } from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.js";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import image from '../../assets/img/site-images/image5.jpg'

export default function ReviewList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const reviewsCollectionRef = collection(db, "reviews");
      const querySnapshot = await getDocs(reviewsCollectionRef);

      const reviewsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const filteredReviews = reviewsData.filter((review) => review.rating > 3);

      console.log(filteredReviews);
      setData(filteredReviews.slice(0, 3));
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="tim-icons icon-shape-star"></span>);
    }
    return stars;
  };

  return (
    <>
      {loading && !data ? (
        <>
          <p className="text-black">Loading...</p>
        </>
      ) : null}
      {!loading && !data ? <></> : null}
      {!loading && data.length > 0 ? (
        <>
          <Row>
            {data.map((review) => {
              return (
                <Col xs="12" md="6" lg="4">
                  <div
                    style={{
                      maxWidth: "23rem",
                      width: "100%",
                      height: "400px",
                      overflowY: "hidden",
                    }}
                  >
                    <CardBody>
                      <h3 className="text-black">"{review.comment}"</h3>
                      <div>
                        <h6 className="text-black">{review.author}</h6>
                        <div>
                          <span style={{ color: "gold" }}>
                            {renderStars(review.rating)}
                          </span>
                        </div>
                        <p className="text-black" style={{ fontSize: "12px" }}>
                          Date:{" "}
                          {new Date(
                            review.date.seconds * 1000
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </CardBody>
                  </div>
                </Col>
              );
            })}
          </Row>
          {data.length > 3 ? (
            <>
              <div>
                <Link to="/reviews">
                  <p>View More</p>
                </Link>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Row>
            <Col xs="12" md="6">
              <div>
                <p>
                  CB Belle Properties is a dedicated team committed to
                  empowering individuals and families on their real estate
                  journey. With a steadfast dedication to understanding your
                  unique needs and aspirations, we offer a comprehensive range
                  of services tailored to your specific goals. Whether you're
                  buying, selling, renting, or investing in property, our
                  team prioritizes your needs above all else.
                  <br />
                  <br />
                  As your trusted partner in real estate, Christol Britton and
                  her CB Belle Properties are committed to delivering unparalleled service and
                  results that positively impact your life now and in the
                  future. Whether you're a first-time buyer or a seasoned
                  investor, let us guide you toward your real estate goals with
                  confidence and care.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" className="p-2">
              <img
                src={image}
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
