import React from 'react'
import './index.css'

export default function ShortenedHeader(props) {
  return (
    <div id='short-header' style={{ backgroundImage: `url(${props.background})` }}>
        <div id='short-header-overlay'>
        {props.heading && (<h1 className='text-white'>{props.heading}</h1>)}
        </div>
    </div>
  )
}
