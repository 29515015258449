import React from "react";
import PageLayout from "components/PageLayout/PageLayout";

export default function PrivacyPolicy() {
  return (
    <PageLayout
      heading="Current Properties"
      background={
        "https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      }
      content={
        <>
          <p>
            Last updated: [Date]
            <br />
            [Your Real Estate Agency/Agent Name] ("us", "we", or "our") operates
            [Your Website URL] (the "Site"). This page informs you of our
            policies regarding the collection, use, and disclosure of Personal
            Information we receive from users of the Site.
            <br />
            Information Collection and Use While using our Site, we may ask you
            to provide us with certain personally identifiable information that
            can be used to contact or identify you. Personally identifiable
            information may include but is not limited to your name, email
            address, postal address, and phone number ("Personal Information").
            <br />
            Log Data Like many site operators, we collect information that your
            browser sends whenever you visit our Site ("Log Data"). This Log
            Data may include information such as your computer's Internet
            Protocol ("IP") address, browser type, browser version, the pages of
            our Site that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
            <br />
            Cookies Cookies are files with small amounts of data, which may
            include an anonymous unique identifier. Cookies are sent to your
            browser from a website and stored on your computer's hard drive.
            Like many sites, we use "cookies" to collect information. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our Site.
            <br />
            Security The security of your Personal Information is important to
            us, but remember that no method of transmission over the Internet,
            or method of electronic storage, is 100% secure. While we strive to
            use commercially acceptable means to protect your Personal
            Information, we cannot guarantee its absolute security.
            <br />
            Changes to This Privacy Policy This Privacy Policy is effective as
            of [Date] and will remain in effect except with respect to any
            changes in its provisions in the future, which will be in effect
            immediately after being posted on this page. We reserve the right to
            update or change our Privacy Policy at any time, and you should
            check this Privacy Policy periodically. Your continued use of the
            Service after we post any modifications to the Privacy Policy on
            this page will constitute your acknowledgment of the modifications
            and your consent to abide and be bound by the modified Privacy
            Policy.
            <br />
            <br />
            Contact Us If you have any questions about this Privacy Policy,
            please contact us. [Your Contact Information]
          </p>
        </>
      }
    />
  );
}
