import React from 'react'
import PageLayout from 'components/PageLayout/PageLayout'
import LendersList from 'components/LendersList/LendersList'
import { Container } from 'reactstrap'

export default function ResourcesLenders() {
  return (
    <PageLayout 
    heading={"Guides and More"}
    background={
        "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      }
      content={
        <Container className="mt-5 mb-5">
            <div>
                <p>View our preferred lenders. A list of trusted experts and professionals!</p>
            </div>
            <LendersList />
        </Container>
      }
    />
  )
}
